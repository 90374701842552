<template>
  <v-container fluid>
    <v-form v-model="valid" class="form-signin">
      <h2 class="form-signin-heading">Please sign in</h2>
      <v-text-field
        v-model="user.username"
        label="Email Address"
        type="username"
        :rules="rules.email"
        autocomplete="false"
        required
      />
      <v-text-field
        v-model="user.password"
        label="Password"
        type="password"
        :rules="rules.password"
        autocomplete="false"
        required
        @keyup.enter="onLogin()"
      />
      <v-btn id="submit" color="primary" :disabled="!valid" depressed @click="onLogin()">
        Sign in
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      rules: {
        email: [v => !!v || 'Email is required'],
        password: [v => !!v || 'Password is required'],
      },
      valid: true,
    };
  },
  computed: {
    returnPath() {
      let returnPath = this.$route.query.returnPath || '/';
      if (returnPath === '/login') returnPath = '/';
      // if returnPath contains /error/ then reassign it to / as we don't want to redirect to error page
      if (returnPath.includes('/error/')) returnPath = '/';
      return returnPath;
    },
  },
  methods: {
    ...mapActions('context', ['loginHardcoded']),
    onLogin() {
      return this.loginHardcoded(this.user).then(() => {
        this.$router.push({ path: this.returnPath });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-signin {
  max-width: 33rem;
  padding: 1.5rem;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 1rem;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1.6rem;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type='email'] {
  margin-bottom: -0.1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type='password'] {
  margin-bottom: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
