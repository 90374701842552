<template>
  <div style="display: contents">
    <td class="hierarchy-level-item__data-cell text-right">
      {{
        formatNumber({ number: salesMAT / 1000, format: numberFormats.integer, zeroAsDash: true })
      }}
    </td>
    <td class="hierarchy-level-item__data-cell text-right">
      {{ formatNumber({ number: promoShare, format: numberFormats.percent, zeroAsDash: true }) }}
    </td>
    <td
      class="hierarchy-level-item__data-cell text-right highlight"
      :class="formatNumberColourClass(impacts.sales)"
    >
      <tooltip
        :value="{
          [$t(
            `gridView.${showRegularImpact ? 'regularImpactInYear' : 'totalImpactInYear'}`
          )]: formatNumber({
            number: impacts.salesInYear,
            format: numberFormats.integer,
            zeroAsDash: true,
          }),
        }"
      >
        {{
          formatNumber({
            number: impacts.sales,
            format: numberFormats.integer,
            zeroAsDash: true,
          })
        }}
      </tooltip>
    </td>
    <td
      class="hierarchy-level-item__data-cell text-right hierarchy-level-item__group-end border-right highlight"
      :class="formatNumberColourClass(impacts.cost, true)"
    >
      <tooltip
        :value="{
          [$t('gridView.costImpactInYear')]: formatNumber({
            number: -impacts.costInYear,
            format: numberFormats.integer,
            zeroAsDash: true,
          }),
        }"
      >
        {{
          formatNumber({
            number: -impacts.cost,
            format: numberFormats.integer,
            zeroAsDash: true,
          })
        }}
      </tooltip>
    </td>
    <td class="hierarchy-level-item__data-cell text-right">
      {{
        formatNumber({
          number: marginProposed,
          format: numberFormats.percent,
          format: extraDecimalPlacesForMarginDelta
            ? numberFormats.percentWithExtraDecimalPlaces
            : numberFormats.percent,
          zeroAsDash: true,
        })
      }}
    </td>
    <td
      class="hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right"
      :class="formatNumberColourClass(marginDelta)"
    >
      {{
        formatNumber({
          number: marginDelta,
          format: extraDecimalPlacesForMarginDelta
            ? numberFormats.percentWithExtraDecimalPlaces
            : numberFormats.percent,
          zeroAsDash: true,
        })
      }}
    </td>
    <template v-if="readFromDatabricks">
      <template v-for="{ competitorKey } in selectedCompetitors">
        <td
          v-if="isPricingGroupLevel"
          :key="`pg-target-distance-${competitorKey}`"
          class="hierarchy-level-item__data-cell text-right"
        >
          {{
            formatNumber({
              number: competitorTargetDistance[competitorKey].targetDistance,
              format: numberFormats.priceFormat,
              zeroAsDash: true,
            })
          }}
        </td>
        <td
          v-else
          :key="`non-pg-target-distance-${competitorKey}`"
          class="hierarchy-level-item__data-cell text-right"
        >
          <v-text-field
            :disabled="!canWriteTargetDistance"
            :value="
              formatNumber({
                number: competitorTargetDistance[competitorKey].targetDistance,
                format: numberFormats.priceFormat,
              })
            "
            type="text"
            @keypress="ensureDecimal($event, true)"
            @focus="previousFieldValue = $event.target.value"
            @change="competitorTargetDistanceChange($event, competitorKey)"
          />
        </td>
        <td
          :key="`live-distance-${competitorKey}`"
          class="hierarchy-level-item__data-cell text-right"
        >
          {{
            formatNumber({
              number: selectedWeightValues[competitorKey].competitorLiveDistance,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td
          :key="`proposed-distance-${competitorKey}`"
          class="hierarchy-level-item__data-cell text-right"
        >
          {{
            formatNumber({
              number: selectedWeightValues[competitorKey].competitorProposedDistance,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td
          :key="`delta-vs-prev-${competitorKey}`"
          class="hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right"
          :class="
            formatNumberColourClass(selectedWeightValues[competitorKey].competitorDeltaVsPrev)
          "
        >
          {{
            formatNumber({
              number: selectedWeightValues[competitorKey].competitorDeltaVsPrev,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="isPricingGroupLevel" class="hierarchy-level-item__data-cell text-right">
        {{
          formatNumber({
            number: competitor1TargetDistance,
            format: numberFormats.priceFormat,
            zeroAsDash: true,
          })
        }}
      </td>
      <td v-else class="hierarchy-level-item__data-cell text-right">
        <v-text-field
          :disabled="!canWriteTargetDistance"
          :value="
            formatNumber({ number: competitor1TargetDistance, format: numberFormats.priceFormat })
          "
          type="text"
          @keypress="ensureDecimal($event, true)"
          @focus="previousFieldValue = $event.target.value"
          @change="competitor1TargetDistanceChange"
        />
      </td>
      <td class="hierarchy-level-item__data-cell text-right">
        {{
          formatNumber({
            number: selectedWeightValues.competitor1LiveDistance,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td class="hierarchy-level-item__data-cell text-right">
        {{
          formatNumber({
            number: selectedWeightValues.competitor1ProposedDistance,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td
        class="hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right"
        :class="formatNumberColourClass(selectedWeightValues.competitor1DeltaVsPrev)"
      >
        {{
          formatNumber({
            number: selectedWeightValues.competitor1DeltaVsPrev,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td v-if="isPricingGroupLevel" class="hierarchy-level-item__data-cell text-right">
        {{
          formatNumber({
            number: competitor2TargetDistance,
            format: numberFormats.priceFormat,
            zeroAsDash: true,
          })
        }}
      </td>
      <td v-else class="hierarchy-level-item__data-cell text-right">
        <v-text-field
          :disabled="!canWriteTargetDistance"
          :value="
            formatNumber({ number: competitor2TargetDistance, format: numberFormats.priceFormat })
          "
          type="text"
          @keypress="ensureDecimal($event, true)"
          @focus="previousFieldValue = $event.target.value"
          @change="competitor2TargetDistanceChange"
        />
      </td>
      <td class="hierarchy-level-item__data-cell text-right">
        {{
          formatNumber({
            number: selectedWeightValues.competitor2LiveDistance,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td class="hierarchy-level-item__data-cell text-right">
        {{
          formatNumber({
            number: selectedWeightValues.competitor2ProposedDistance,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td
        class="hierarchy-level-item__data-cell hierarchy-level-item__group-end text-right highlight border-right"
        :class="formatNumberColourClass(selectedWeightValues.competitor2DeltaVsPrev / 1000)"
      >
        {{
          formatNumber({
            number: selectedWeightValues.competitor2DeltaVsPrev,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
    </template>
    <td class="hierarchy-level-item__data-cell hierarchy-level-item__group-end border-right">
      <tension-chart v-bind="tensionChartProps" />
    </td>
    <td class="hierarchy-level-item__data-cell text-right">
      <v-chip class="remove-margin" small>
        {{ formatNumber({ number: numPriceChanges || 0, format: numberFormats.integer }) }}
      </v-chip>
    </td>
    <td class="alert-cell" />
  </div>
</template>

<script>
import { get, pick, findIndex, find, round, keyBy, union, differenceBy } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import numberFormats from '@enums/number-formats';
import {
  competitorIndexWeightingTypes,
  extraDecimalPlacesForMarginDelta,
} from '@enums/feature-flags';

export default {
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
    isStoreGroupAggregation: {
      type: Boolean,
      default: () => false,
    },

    salesMAT: { type: [Number, null] },
    promoShare: { type: [Number, null] },
    salesImpactMAT: { type: [Number, null] },
    regularSalesImpact: { type: [Number, null] },
    totalImpactInYear: { type: [Number, null] },
    regularImpactInYear: { type: [Number, null] },
    totalCostImpact: { type: [Number, null] },
    costImpactInYear: { type: [Number, null] },
    regularCostImpactInYear: { type: [Number, null] },
    regularCostImpact: { type: [Number, null] },
    marginProposed: { type: [Number, null] },
    marginDelta: { type: [Number, null] },
    economicTension: { type: [Number, null] },
    competitorTension: { type: [Number, null] },
    architectureTension: { type: [Number, null] },
    storeGroupTension: { type: [Number, null] },
    competitors: { type: Array, default: () => [] },

    competitor1LiveDistance: { type: [Number, null] },
    competitor1ProposedDistance: { type: [Number, null] },
    competitor1DeltaVsPrev: { type: [Number, null] },
    competitor2LiveDistance: { type: [Number, null] },
    competitor2ProposedDistance: { type: [Number, null] },
    competitor2DeltaVsPrev: { type: [Number, null] },

    competitor1LiveDistancePrice: { type: [Number, null] },
    competitor1ProposedDistancePrice: { type: [Number, null] },
    competitor1DeltaVsPrevPrice: { type: [Number, null] },
    competitor2LiveDistancePrice: { type: [Number, null] },
    competitor2ProposedDistancePrice: { type: [Number, null] },
    competitor2DeltaVsPrevPrice: { type: [Number, null] },

    competitor1LiveDistanceVolume: { type: [Number, null] },
    competitor1ProposedDistanceVolume: { type: [Number, null] },
    competitor1DeltaVsPrevVolume: { type: [Number, null] },
    competitor2LiveDistanceVolume: { type: [Number, null] },
    competitor2ProposedDistanceVolume: { type: [Number, null] },
    competitor2DeltaVsPrevVolume: { type: [Number, null] },

    numPriceChanges: { type: [Number, null] },

    competitorTargetDistances: { type: Array, default: () => [] },
  },

  data() {
    return {
      numberFormats,
      competitorIndexWeightingTypes,

      tensionChartOptions: {
        chart: {
          width: 80,
          height: 20,
          spacing: [5, 0, 5, 0],
          style: {
            margin: '0 auto',
          },
          yAxis: {
            plotLines: [
              {
                value: 0,
                color: 'green',
                dashStyle: 'shortdash',
                width: 2,
              },
            ],
          },
        },
      },
    };
  },

  computed: {
    ...mapState('gridView', [
      'selectedWeighting',
      'selectedCompetitors',
      'showRegularImpact',
      'readFromDatabricks',
    ]),
    ...mapState('clientConfig', ['toggleLogic', 'hierarchy']),
    ...mapGetters('context', ['isPricingManager', 'isPricingSpecialist']),

    isPricingGroupLevel() {
      return this.level === this.hierarchy.pricingGroupLevel;
    },

    canWriteTargetDistance() {
      return !this.isStoreGroupAggregation && (this.isPricingManager || this.isPricingSpecialist);
    },

    validCompetitors() {
      if (this.competitors.length === this.selectedCompetitors.length) return this.competitors;
      // Find missing competitors from selectedCompetitors
      const missingCompetitors = differenceBy(
        this.selectedCompetitors,
        this.competitors,
        'competitorKey'
      );

      return union(this.competitors, missingCompetitors);
    },
    competitorTargetDistance() {
      return keyBy(
        this.validCompetitors.map(c => {
          const target = find(this.competitorTargetDistances, ['competitorKey', c.competitorKey]);
          return {
            competitorKey: c.competitorKey,
            targetDistance: get(target, 'targetDistance', null),
          };
        }),
        'competitorKey'
      );
    },

    competitor1TargetDistance() {
      const competitor1Distance = find(this.competitorTargetDistances, [
        'competitorKey',
        this.selectedCompetitors[0].competitorKey,
      ]);
      return get(competitor1Distance, 'targetDistance', null);
    },

    competitor2TargetDistance() {
      const competitor2Distance = find(this.competitorTargetDistances, [
        'competitorKey',
        this.selectedCompetitors[1].competitorKey,
      ]);
      return get(competitor2Distance, 'targetDistance', null);
    },

    weightingFunctionMap() {
      return {
        [competitorIndexWeightingTypes.sales]: this.sales,
        [competitorIndexWeightingTypes.volume]: this.volume,
        [competitorIndexWeightingTypes.price]: this.price,
      };
    },

    extraDecimalPlacesForMarginDelta() {
      return this.toggleLogic[extraDecimalPlacesForMarginDelta];
    },

    selectedWeightValues() {
      return this.weightingFunctionMap[this.selectedWeighting];
    },

    impacts() {
      const totalImpacts = {
        sales: this.salesImpactMAT / 1000,
        salesInYear: this.totalImpactInYear / 1000,
        cost: this.totalCostImpact / 1000,
        costInYear: this.costImpactInYear / 1000,
      };
      const regularImpacts = {
        sales: this.regularSalesImpact / 1000,
        salesInYear: this.regularImpactInYear / 1000,
        cost: this.regularCostImpact / 1000,
        costInYear: this.regularCostImpactInYear / 1000,
      };
      return this.showRegularImpact ? regularImpacts : totalImpacts;
    },

    sales() {
      if (this.readFromDatabricks) {
        return keyBy(
          this.validCompetitors.map(c => {
            return {
              competitorKey: c.competitorKey,
              competitorLiveDistance: c.competitorLiveDistance,
              competitorProposedDistance: c.competitorProposedDistance,
              competitorDeltaVsPrev: c.competitorDeltaVsPrev,
            };
          }),
          'competitorKey'
        );
      }
      return {
        competitor1LiveDistance: this.competitor1LiveDistance,
        competitor1ProposedDistance: this.competitor1ProposedDistance,
        competitor1DeltaVsPrev: this.competitor1DeltaVsPrev,
        competitor2LiveDistance: this.competitor2LiveDistance,
        competitor2ProposedDistance: this.competitor2ProposedDistance,
        competitor2DeltaVsPrev: this.competitor2DeltaVsPrev,
      };
    },

    price() {
      if (this.readFromDatabricks) {
        return keyBy(
          this.validCompetitors.map(c => {
            return {
              competitorKey: c.competitorKey,
              competitorLiveDistance: c.competitorLiveDistancePrice,
              competitorProposedDistance: c.competitorProposedDistancePrice,
              competitorDeltaVsPrev: c.competitorDeltaVsPrevPrice,
            };
          }),
          'competitorKey'
        );
      }
      return {
        competitor1LiveDistance: this.competitor1LiveDistancePrice,
        competitor1ProposedDistance: this.competitor1ProposedDistancePrice,
        competitor1DeltaVsPrev: this.competitor1DeltaVsPrevPrice,
        competitor2LiveDistance: this.competitor2LiveDistancePrice,
        competitor2ProposedDistance: this.competitor2ProposedDistancePrice,
        competitor2DeltaVsPrev: this.competitor2DeltaVsPrevPrice,
      };
    },

    volume() {
      if (this.readFromDatabricks) {
        return keyBy(
          this.validCompetitors.map(c => {
            return {
              competitorKey: c.competitorKey,
              competitorLiveDistance: c.competitorLiveDistanceVolume,
              competitorProposedDistance: c.competitorProposedDistanceVolume,
              competitorDeltaVsPrev: c.competitorDeltaVsPrevVolume,
            };
          }),
          'competitorKey'
        );
      }
      return {
        competitor1LiveDistance: this.competitor1LiveDistanceVolume,
        competitor1ProposedDistance: this.competitor1ProposedDistanceVolume,
        competitor1DeltaVsPrev: this.competitor1DeltaVsPrevVolume,
        competitor2LiveDistance: this.competitor2LiveDistanceVolume,
        competitor2ProposedDistance: this.competitor2ProposedDistanceVolume,
        competitor2DeltaVsPrev: this.competitor2DeltaVsPrevVolume,
      };
    },

    // TODO: remove once tension chart has been refactored
    tensionChartProps() {
      return {
        options: this.tensionChartOptions,
        economicReferencePrice: this.economicTension,
        competitorReferencePrice: this.competitorTension,
        architectureReferencePrice: this.architectureTension,
        storeGroupReferencePrice: this.storeGroupTension,
        scenarioPrice: 0,
      };
    },
  },

  methods: {
    competitorTargetDistanceChange(newValue, competitorKey) {
      const targetDistance = this.formatTargetDistance(newValue);
      const competitorDistance = { competitorKey, targetDistance };
      const index = findIndex(this.competitorTargetDistances, ['competitorKey', competitorKey]);
      if (index < 0) {
        this.$set(
          this.competitorTargetDistances,
          this.competitorTargetDistances.length,
          competitorDistance
        );
      } else {
        this.$set(this.competitorTargetDistances, index, competitorDistance);
      }
      this.updateCompetitorTargetDistances();
    },

    competitor1TargetDistanceChange(newValue) {
      const targetDistance = this.formatTargetDistance(newValue);
      const competitor1Distance = pick(this.selectedCompetitors[0], ['competitorKey']);
      competitor1Distance.targetDistance = targetDistance;
      const index = findIndex(this.competitorTargetDistances, [
        'competitorKey',
        this.selectedCompetitors[0].competitorKey,
      ]);
      if (index < 0) {
        this.$set(
          this.competitorTargetDistances,
          this.competitorTargetDistances.length,
          competitor1Distance
        );
      } else {
        this.$set(this.competitorTargetDistances, index, competitor1Distance);
      }
      this.updateCompetitorTargetDistances();
    },

    competitor2TargetDistanceChange(newValue) {
      const targetDistance = this.formatTargetDistance(newValue);
      const competitor2Distance = pick(this.selectedCompetitors[1], ['competitorKey']);
      competitor2Distance.targetDistance = targetDistance;
      const index = findIndex(this.competitorTargetDistances, [
        'competitorKey',
        this.selectedCompetitors[1].competitorKey,
      ]);
      if (index < 0) {
        this.$set(
          this.competitorTargetDistances,
          this.competitorTargetDistances.length,
          competitor2Distance
        );
      } else {
        this.$set(this.competitorTargetDistances, index, competitor2Distance);
      }
      this.updateCompetitorTargetDistances();
    },

    formatTargetDistance(value) {
      const targetDistance = this.formatStringToNumber(value);
      if (!targetDistance && targetDistance !== 0) return;
      return round(targetDistance, 2);
    },

    updateCompetitorTargetDistances() {
      this.$emit('updateCompetitorTargetDistances', this.competitorTargetDistances);
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
@import '@style/components/_hierarchy-item-shared.scss';

.hierarchy-level-item__group-end {
  .actions {
    @include hierarchy-item-actions-panel();
  }

  .v-btn {
    font-size: 1.25rem;
  }

  .v-messages {
    display: none;
  }

  .v-input__slot {
    margin-bottom: unset !important;
  }

  .checkbox {
    padding-top: $hierarchy-item-checkbox-padding-top;
  }

  .hierarchy-level-item__approve-btn.v-btn {
    height: 2.4rem;
    padding: unset;
    margin-right: 0 !important;
  }
}

.alert-cell {
  background-color: $pricing-background !important;
  border-bottom: none !important;
  width: $grid-view-alert-column-width !important;
  border-top: none !important;
}

//* { outline: solid 0.25rem hsla(210, 100%, 100%, 0.5); }
.hierarchy-level-item {
  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &--level-0 {
    td:not(.alert-cell) {
      border-top: solid 0.2rem $pricing-light-blue;
    }
    .hierarchy-level-item__heading {
      color: $pricing-light-blue;
    }
  }

  &--level-1 {
    td:not(.alert-cell) {
      border-top: solid 0.1rem $pricing-grey-dark;
    }
  }

  &--level-2 {
    td:not(.alert-cell) {
      border-bottom: solid 0.1rem $pricing-grey-dark;
    }
  }

  &--level-3 {
    td {
      border-bottom: solid 0.1rem $pricing-grey-dark;
      &.hierarchy-level-item__heading {
        font-weight: 300;
      }

      &.hierarchy-level-item__heading.disabled {
        color: #8ba4b7;
      }
    }
  }

  &__main {
    color: $text-color;
    td:not(.alert-cell) {
      font-size: 1.3rem;
      line-height: 1.7rem;

      &.highlight {
        background-color: $grid-view-highlight;
      }
    }
  }

  &__data-cell {
    border-bottom: solid 0.1rem $pricing-grey-dark;
    font-weight: 600;
    input {
      padding: 0px;
      text-align: right;
      font-size: 1.3rem;
    }
    .v-input {
      padding: 0px;
      margin: 0px;
    }
    .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }
  }
}

.border-right {
  border-right: 0.1rem solid $pricing-grey-dark;
}

.border-bottom {
  border-bottom: 0.1rem solid $pricing-grey-dark;
}

.remove-margin {
  margin: 0;
}
</style>
