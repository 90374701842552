<template>
  <!-- eslint-disable vue/no-unused-vars -->
  <v-data-table
    dense
    :loading="
      isCalculatingImpactFactor || !architectureProductsLoaded || architectureProductsLoading
    "
    class="products-table"
    :options.sync="pagination"
    :sort-desc.sync="pagination.descending"
    :headers="flattenHeaders"
    :items="architectureProductList"
    item-key="_id"
    hide-default-header
    hide-default-footer
    :custom-sort="customProductSort"
  >
    <template v-slot:top>
      <slot />
    </template>
    <template v-slot:header="{ props }">
      <thead>
        <tr v-for="(row, index) in allHeaders" :key="`header-${index}`" class="v-data-table-header">
          <th
            v-for="header in row"
            :key="`header-${index}-${header.key || header.value}`"
            :colspan="header.colspan"
            :rowspan="header.rowspan"
            :class="[...getHeaderClasses(header, pagination), header.class]"
            @click="changeSort({ header })"
          >
            {{ header.text }}
            <v-icon v-if="header.sortable" size="1.4rem" class="v-data-table-header__icon">
              arrow_upward
            </v-icon>
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:item="props">
      <tr :class="{ [generalCssClasses.evenRows]: props.index % 2 }">
        <td v-for="column in flattenHeaders" :key="column.key" :class="column.class">
          <template v-if="column.key === columnKeys.productKey">
            {{ props.item.article.productKeyDisplay }}
          </template>
          <template v-else-if="column.key === columnKeys.toolStoreGroup">
            <tooltipped-truncated-field
              :text="storegroupKeyNameMap[props.item.toolStoreGroupKey]"
              :truncation-length="truncationLength.toolStoreGroupDescription"
            />
          </template>
          <template v-else-if="column.key === columnKeys.article">
            <tooltipped-truncated-field
              :text="props.item.article.productDescription"
              :truncation-length="getProductDescriptionWidth"
            />
          </template>
          <norm-weight-tooltip
            v-else-if="column.key === columnKeys.size"
            :value="props.item.article.productSizeType"
            :norm-weight="props.item.attributes.normWeight"
            :norm-weight-uo-m="props.item.attributes.normWeightUnitOfMeasure"
          />
          <template v-else-if="column.key === columnKeys.packageType">
            {{ props.item.attributes.packageTypeDescription }}
          </template>
          <template v-else-if="column.key === columnKeys.costPrice">
            {{
              formatNumber({
                number: getIntentionCost(props.item),
                format: numberFormats.priceFormat,
                nullAsDash: true,
              })
            }}
          </template>
          <template v-else-if="column.key === columnKeys.livePrice">
            {{
              formatNumber({
                number: getLivePrice(props.item),
                format: numberFormats.priceFormat,
                nullAsDash: true,
              })
            }}
          </template>
          <template v-else-if="column.key === columnKeys.subGroup">
            {{ props.item.subGroup }}
          </template>
          <div
            v-else-if="column.key === columnKeys.impactDrivers"
            :class="[
              props.item.productImpact.impactFactorCustomOffset !== 1 ? 'grey--text' : '',
              ...getImpactFactorClass(props.item.productImpact),
            ]"
          >
            {{ getImpactFactor(props.item.productImpact.impactFactor) }}
          </div>
          <!-- <form novalidate> is required to disable browser native validation  -->
          <!-- if "step"="0.1" and a user enters 0.131, the following notification will be displayed:  -->
          <!-- please enter a valid value. the two nearest valid values are 0.1 and 0.2 -->
          <form
            v-else-if="column.key === columnKeys.impactOffset"
            :class="getImpactFactorClass(props.item.productImpact)"
            novalidate
          >
            <tooltip
              position="bottom-left"
              :value="
                $t(
                  'settings.setArchitectureRules.architectureDriversRules.productTable.offsetError'
                )
              "
              :disabled="validOffsetCheck(props.item.productImpact.impactFactorCustomOffset)"
              :is-error="hasValidationError"
            >
              <v-text-field
                v-model.number="props.item.productImpact.impactFactorCustomOffset"
                :background-color="owWhite"
                :rules="rules"
                step="0.1"
                type="number"
                hide-details
                single-line
                height="2rem"
                class="pt-0"
                :disabled="!!architectureDriverUpdatesAvailable || disabled"
                @update:error="hasValidationError"
                @input="
                  calculateFinalImpact({
                    productKey: props.item.article.productKey,
                    toolStoreGroupKey: props.item.toolStoreGroupKey,
                    impactFactor: props.item.productImpact.impactFactor,
                    offset: props.item.productImpact.impactFactorCustomOffset,
                  })
                "
              />
            </tooltip>
          </form>
          <tooltip
            v-else-if="column.key === columnKeys.impactFinal"
            position="bottom-left"
            :value="$t('settings.setArchitectureRules.finalImpactCalculation')"
          >
            {{ getImpactFactor(props.item.productImpact.impactFactorFinal) }}
          </tooltip>
        </td>
      </tr>
    </template>

    <template slot="footer">
      <v-row class="ma-0">
        <v-col md="2">
          <v-btn
            v-show="finalImpactUpdateAvailable && !architectureProductsLoading"
            :disabled="disabled"
            color="success ml-2 mt-1"
            class="save"
            small
            depressed
            @click="updateFinalImpacts"
          >
            {{ $t('actions.save') }}
          </v-btn>
        </v-col>
        <v-col md="6" offset-md="4">
          <v-pagination v-model="pagination.page" :length="noOfPages" />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { get, ceil, map } from 'lodash';
import colours from '../../../../ow-colors';
import { architectureGroupLevel } from '@enums/hierarchy';
import { hierarchyOption } from '@enums/filter-options';
import getPriceFieldMixin from '../../../../mixins/getPriceField';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import tablesMixin from '../../../../mixins/tables';
import numberFormats from '@enums/number-formats';
import { generalCssClasses } from '@enums/tables';
import { useZones } from '@enums/feature-flags';
import DataTypes from '@enums/data-types';
import { ascending, descending } from '@enums/sort-direction';
import { truncationLength } from '@sharedModules/config/products-grid';
import { getCompareProductsFunc } from '../../../../utils/sort-store-groups-util';

const columnKeys = Object.freeze({
  productKey: 'product-key',
  toolStoreGroup: 'tool-store-group',
  article: 'article',
  size: 'size',
  packageType: 'package-type',
  costPrice: 'cost-price',
  livePrice: 'live-price',
  subGroup: 'sub-group',
  impactSection: 'impact',
  impactDrivers: 'impact-drivers',
  impactOffset: 'impact-offset',
  impactFinal: 'impact-final',
});

export default {
  mixins: [getPriceFieldMixin, featureFlagsMixin, tablesMixin],

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    architectureProducts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tab: null,
      pagination: {
        sortBy: ['article.productDescription'],
        descending: false,
        itemsPerPage: 15,
      },
      rules: [
        value =>
          this.validOffsetCheck(value) ||
          this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.offsetError'
          ),
      ],
      hasOffsetValidationError: false,
      architectureDriverUpdatesAvailable: false,
      owWhite: colours.owWhite,
      numberFormats,
      useZones,
      generalCssClasses,
      columnKeys,
      truncationLength,
    };
  },

  computed: {
    ...mapState('architectureGroup', [
      'finalImpactUpdateAvailable',
      'architectureProductsCount',
      'filteredAttributes',
      'architectureProductsLoaded',
      'architectureProductsLoading',
    ]),
    ...mapState('architectureDrivers', ['isCalculatingImpactFactor']),
    ...mapState('filters', [
      'architectureGroup',
      'selectedPriceType', // used in getPriceField mixin
    ]),
    ...mapState('clientConfig', ['storeGroupOrderConfig']),
    ...mapState('storeGroupRelationships', ['storegroupKeyNameMap']),
    ...mapGetters('hierarchy', ['getSubGroupsForProduct']),

    noOfPages() {
      return ceil(this.architectureProductList.length / this.pagination.itemsPerPage);
    },

    firstRowHeaders() {
      return [
        {
          key: columnKeys.productKey,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.productKey'
          ),
          sortable: true,
          value: 'article.productKeyDisplay',
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.str,
        },
        {
          key: columnKeys.toolStoreGroup,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.toolStoreGroup'
          ),
          sortable: true,
          value: 'toolStoreGroupDescription',
          featureFlag: useZones,
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.str,
        },
        {
          key: columnKeys.article,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.article'
          ),
          sortable: true,
          value: 'article.productDescription',
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.str,
        },
        {
          key: columnKeys.size,
          text: this.$t('settings.setArchitectureRules.architectureDriversRules.productTable.size'),
          sortable: true,
          value: 'article.productSizeType',
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.str,
        },
        {
          key: columnKeys.packageType,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.packageTypeDescription'
          ),
          sortable: true,
          value: 'attributes.packageTypeDescription',
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.str,
        },
        {
          key: columnKeys.costPrice,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.costPrice'
          ),
          sortable: true,
          value: `${this.getPriceFieldObjectPath('intentionCost')}`,
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.number,
        },
        {
          key: columnKeys.livePrice,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.livePrice'
          ),
          sortable: true,
          value: `${this.getPriceFieldObjectPath('livePrice')}`,
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.number,
        },
        {
          key: columnKeys.subGroup,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.subGroup'
          ),
          sortable: true,
          value: 'subGroup',
          colspan: 1,
          rowspan: 2,
          datatype: DataTypes.str,
        },
        {
          key: columnKeys.impactSection,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.impact'
          ),
          class: 'border-left border-right',
          colspan: 3,
          rowspan: 1,
          datatype: DataTypes.str,
        },
      ];
    },

    secondRowHeaders() {
      const commonImpactColumnClass = 'impact';
      return [
        {
          key: columnKeys.impactDrivers,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.drivers'
          ),
          sortable: true,
          value: 'productImpact.impactFactor',
          class: `${commonImpactColumnClass} border-left`,
          colspan: 1,
          rowspan: 1,
          datatype: DataTypes.number,
        },
        {
          key: columnKeys.impactOffset,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.offset'
          ),
          sortable: true,
          value: 'productImpact.impactFactorCustomOffset',
          class: commonImpactColumnClass,
          colspan: 1,
          rowspan: 1,
          datatype: DataTypes.number,
        },
        {
          key: columnKeys.impactFinal,
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.finalImpact'
          ),
          sortable: true,
          value: 'productImpact.impactFactorFinal',
          class: `${commonImpactColumnClass} border-right`,
          colspan: 1,
          rowspan: 1,
          datatype: DataTypes.number,
        },
      ];
    },

    allHeaders() {
      return [
        this.filterArrayByFeatureFlag(this.firstRowHeaders),
        this.filterArrayByFeatureFlag(this.secondRowHeaders),
      ];
    },

    flattenHeaders() {
      return this.getFlattenColumns(...this.allHeaders);
    },

    getProductDescriptionWidth() {
      // calculated based on the screen width
      return 0.014 * window.innerWidth;
    },

    architectureProductList() {
      return map(this.architectureProducts, product => {
        product.subGroup = this.getSubGroupsForProduct({
          productKey: product.article.productKey,
          toolStoreGroupKey: product.toolStoreGroupKey,
        });
        return product;
      });
    },
  },

  created() {
    const filters = [
      {
        attributeKey: architectureGroupLevel,
        attributeDescription: this.$t('pricing.category'),
        attributeFilterType: hierarchyOption,
        attributeValue: [this.architectureGroup],
      },
    ];
    this.getFilteredAttributes({ params: { where: filters } });
  },

  events: {
    async onArchitectureDriverHasUpdates(hasUpdates) {
      this.architectureDriverUpdatesAvailable = hasUpdates;
    },
  },

  methods: {
    ...mapActions('architectureGroup', [
      'calculateFinalImpact',
      'updateFinalImpacts',
      'getFilteredAttributes',
    ]),
    changeSort({ header, setDirection = true }) {
      if (!header.sortable) {
        return;
      }

      if (this.pagination.sortBy[0] === header.value && setDirection) {
        this.pagination.descending = !this.pagination.descending;
        return;
      }

      this.pagination.sortBy = [header.value];
      if (setDirection) this.pagination.descending = false;
    },
    hasValidationError(isError) {
      this.hasOffsetValidationError = !!isError;
    },
    validOffsetCheck(offsetValue) {
      return offsetValue > 0;
    },
    getImpactFactor(impactFactor) {
      if (!impactFactor) return 'NaN';
      return this.formatNumber({ number: impactFactor, format: numberFormats.impactFactor });
    },

    getImpactFactorClass(productImpact) {
      return !productImpact.impactFactor ? 'update-disabled' : '';
    },

    getLivePrice(item) {
      const field = this.getPriceFieldObjectPath('livePrice');
      return get(item, field);
    },

    getIntentionCost(item) {
      const field = this.getPriceFieldObjectPath('intentionCost');
      return get(item, field);
    },

    customProductSort(items, [sortBy], [isDesc]) {
      let needChangeSort = false;
      // handle case where key changes for prices, match firstRowHeaders
      if (['intentionCost', 'livePrice'].some(v => sortBy.startsWith(v))) {
        const sortByRootKey = sortBy.split('.')[0];
        const updatedSortBy = `${this.getPriceFieldObjectPath(sortByRootKey)}`;
        if (sortBy !== updatedSortBy) {
          sortBy = updatedSortBy;
          needChangeSort = true;
        }
      }

      const filteredHeader = this.allHeaders.flat().find(el => el.value === sortBy);
      if (needChangeSort) this.changeSort({ header: filteredHeader, setDirection: false });

      const columnDatatype = filteredHeader.datatype || DataTypes.str;
      const compareFunc = getCompareProductsFunc({
        sortByField: sortBy,
        sortDirection: isDesc ? descending : ascending,
        dataType: columnDatatype,
        storeGroupOrderConfig: this.storeGroupOrderConfig,
        compareByAbsoluteValues: false,
        compareByToolStoreGroup: true,
        useArticle: true,
      });

      return items.sort(compareFunc);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$border-style: 0.1rem solid $table-border-color;

.products-table {
  table {
    th {
      background-color: $pricing-white !important; // Vuetify 2.7.2 chaged styles overrides
    }
  }

  tbody {
    td {
      font-size: 1.5rem;
      padding: 0 10px;
    }

    tr {
      background-color: $pricing-white !important;

      &.even-rows {
        background-color: $grid-view-dense-row-color !important;
      }

      &:hover {
        background-color: $grid-view-hover-row-color !important;
      }
    }
  }
}

.impact {
  background-color: $rulesetLightBlueImpact;
  width: 7rem;
}

.border-right {
  border-right: $border-style;
}
.border-left {
  border-left: $border-style;
}

.update-disabled {
  background-color: $pricing-grey !important;
}
</style>
