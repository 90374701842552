<template>
  <div style="display: contents;">
    <tr :class="['scenario-selector-row', isCandidateScenario ? 'selected' : '']">
      <!-- delete dialog -->
      <alert-dialog
        :is-open="isDeleteDialogOpen"
        :ok-btn-text="$t('actions.delete')"
        @onOk="runDeleteScenario"
        @onCancel="closeDeleteDialog"
      >
        <template v-slot:header>{{ $t('dialogs.deleteScenario.header') }}</template>
        <template v-slot:body>
          {{ $t('dialogs.deleteScenario.body') }}
          <strong>{{ scenarioToDelete ? scenarioToDelete.scenarioDescription : '' }}</strong>
        </template>
      </alert-dialog>
      <td class="border-right" :class="{ 'border-bottom': displayBorderUnderName }">
        <v-row no-gutters>
          <v-col class="d-flex align-center">
            <v-radio-group v-model="isCandidateScenario">
              <v-radio
                :value="true"
                :disabled="isCandidateScenario || !isEditable"
                @click="openModal()"
              />
            </v-radio-group>
            <span class="scenario-name">
              <tooltipped-truncated-field
                :text="scenario.scenarioDescription"
                :truncation-length="scenarioDescriptionTruncationLength"
              />
            </span>
            <v-dialog v-model="confirmationDialog" width="40rem">
              <v-card>
                <v-card-title class="headline lighten-2" primary-title>
                  {{ $t('gridView.changeScenario.modalTitle') }}
                </v-card-title>
                <v-card-text>
                  <div class="dialog-copy pt-5 pb-7">
                    {{ $t('gridView.changeScenario.modalCopy') }}
                  </div>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      outlined
                      class="cancel-btn"
                      small
                      text
                      depressed
                      @click="confirmationDialog = false"
                    >
                      {{ $t('actions.cancel') }}
                    </v-btn>
                    <v-btn class="primary" small depressed @click="changeScenario">
                      {{ $t('actions.submit') }}
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="1">
            <v-btn
              v-if="canDelete"
              class="mr-2 btn-delete"
              color="primary"
              small
              :disabled="deleting"
              :loading="deleting"
              depressed
              @click="openDeleteDialog(scenario)"
            >
              <v-icon v-once small> fa-trash </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" class="margin-lever-container">
            <tooltip :value="$t('gridView.tooltips.marginLeverUpdate')">
              <v-text-field
                :value="
                  formatNumber({ number: scenario.marginLever, format: numberFormats.priceFormat })
                "
                type="text"
                :disabled="!isEditable"
                class="d-flex flex-row-reverse"
                :rules="rules.marginLever"
                @keypress="ensureDecimal($event, true)"
                @focus="previousFieldValue = $event.target.value"
                @change="marginLeverChange($event, scenario.marginLever)"
              />
            </tooltip>
          </v-col>
        </v-row>
      </td>
      <td class="text-right border-bottom">
        {{
          formatNumber({
            number: selectedPeriodAggregation.salesMAT / 1000,
            format: numberFormats.integer,
            zeroAsDash: true,
          })
        }}
      </td>
      <td class="text-right border-bottom">
        {{
          formatNumber({
            number: selectedPeriodAggregation.promoShare,
            format: numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td
        class="text-right highlight border-bottom"
        :class="formatNumberColourClass(impacts.sales)"
      >
        <tooltip
          :value="{
            [$t(
              `gridView.${showRegularImpact ? 'regularImpactInYear' : 'totalImpactInYear'}`
            )]: formatNumber({
              number: impacts.salesInYear,
              format: numberFormats.integer,
              zeroAsDash: true,
            }),
          }"
        >
          {{
            formatNumber({
              number: impacts.sales,
              format: numberFormats.integer,
              zeroAsDash: true,
            })
          }}
        </tooltip>
      </td>
      <td
        class="text-right highlight border-right border-bottom"
        :class="formatNumberColourClass(impacts.cost, true)"
      >
        <tooltip
          :value="{
            [$t('gridView.costImpactInYear')]: formatNumber({
              number: -impacts.costInYear,
              format: numberFormats.integer,
              zeroAsDash: true,
            }),
          }"
        >
          {{
            formatNumber({
              number: -impacts.cost,
              format: numberFormats.integer,
              zeroAsDash: true,
            })
          }}
        </tooltip>
      </td>
      <td class="text-right border-bottom">
        {{
          formatNumber({
            number: selectedPeriodAggregation.marginProposed,
            format: extraDecimalPlacesForMarginDelta
              ? numberFormats.percentWithExtraDecimalPlaces
              : numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <td
        class="text-right highlight border-right border-bottom"
        :class="formatNumberColourClass(selectedPeriodAggregation.marginDelta)"
      >
        {{
          formatNumber({
            number: selectedPeriodAggregation.marginDelta,
            format: extraDecimalPlacesForMarginDelta
              ? numberFormats.percentWithExtraDecimalPlaces
              : numberFormats.percent,
            zeroAsDash: true,
          })
        }}
      </td>
      <template v-if="readFromDatabricks">
        <template v-for="{ competitorKey } in selectedCompetitors">
          <td class="text-right border-bottom">
            {{
              formatNumber({ number: null, format: numberFormats.priceFormat, zeroAsDash: true })
            }}
          </td>
          <td class="text-right border-bottom">
            {{
              formatNumber({
                number: selectedWeightValues[competitorKey].competitorLiveDistance,
                format: numberFormats.percent,
                zeroAsDash: true,
              })
            }}
          </td>
          <td class="text-right border-bottom">
            {{
              formatNumber({
                number: selectedWeightValues[competitorKey].competitorProposedDistance,
                format: numberFormats.percent,
                zeroAsDash: true,
              })
            }}
          </td>
          <td
            class="text-right highlight border-right border-bottom"
            :class="
              formatNumberColourClass(selectedWeightValues[competitorKey].competitorDeltaVsPrev)
            "
          >
            {{
              formatNumber({
                number: selectedWeightValues[competitorKey].competitorDeltaVsPrev,
                format: numberFormats.percent,
                zeroAsDash: true,
              })
            }}
          </td>
        </template>
      </template>
      <template v-else>
        <td class="text-right border-bottom">
          {{ formatNumber({ number: null, format: numberFormats.priceFormat, zeroAsDash: true }) }}
        </td>
        <td class="text-right border-bottom">
          {{
            formatNumber({
              number: selectedWeightValues.competitor1LiveDistance,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td class="text-right border-bottom">
          {{
            formatNumber({
              number: selectedWeightValues.competitor1ProposedDistance,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td
          class="text-right highlight border-right border-bottom"
          :class="formatNumberColourClass(selectedWeightValues.competitor1DeltaVsPrev)"
        >
          {{
            formatNumber({
              number: selectedWeightValues.competitor1DeltaVsPrev,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td class="text-right border-bottom">
          {{ formatNumber({ number: null, format: numberFormats.priceFormat, zeroAsDash: true }) }}
        </td>
        <td class="text-right border-bottom">
          {{
            formatNumber({
              number: selectedWeightValues.competitor2LiveDistance,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td class="text-right border-bottom">
          {{
            formatNumber({
              number: selectedWeightValues.competitor2ProposedDistance,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
        <td
          class="group-end text-right highlight border-right border-bottom"
          :class="formatNumberColourClass(selectedWeightValues.competitor2DeltaVsPrev)"
        >
          {{
            formatNumber({
              number: selectedWeightValues.competitor2DeltaVsPrev,
              format: numberFormats.percent,
              zeroAsDash: true,
            })
          }}
        </td>
      </template>

      <td class="text-right border-right border-bottom">
        <tension-chart v-bind="tensionChartProps" />
      </td>
      <td class="text-right border-bottom">
        <v-chip class="remove-margin" small>
          {{
            formatNumber({
              number: scenario.numPriceChanges,
              format: numberFormats.integer,
              nullAsDash: true,
            })
          }}
        </v-chip>
      </td>
      <td class="alert-cell" />
    </tr>
    <feature-toggle :toggle="displayStoreGroupPenalty">
      <store-group-aggregations
        :key="scenario._id"
        :parent-id="levelEntryKey"
        :level="hierarchy.pricingGroupLevel"
        :scenario-key="scenario.scenarioKey"
      />
      <tr class="details-row">
        <td colspan="1" class="border-bottom" />
        <td colspan="14" class="border-bottom" />
        <td colspan="2" class="border-bottom">
          <div class="clickable my-auto" @click="toggleDisplayStoreGroupAggregations">
            {{ storeGroupExpanded ? $t('actions.close') : $t('actions.details') }}
            <i :class="[storeGroupExpanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down']" />
          </div>
        </td>
        <td class="alert-cell" />
      </tr>
    </feature-toggle>
  </div>
</template>

<script>
import { get, debounce, keyBy, round, union, differenceBy } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';

import { candidate } from '@enums/scenario-version';
import numberFormats from '@enums/number-formats';
import {
  competitorIndexWeightingTypes,
  displayStoreGroupPenalty,
  extraDecimalPlacesForMarginDelta,
} from '@enums/feature-flags';
import featureFlagsMixin from '../../../mixins/featureFlags';
import { mapAggregations } from '@sharedModules/data/utils/time-flexible-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [featureFlagsMixin],

  props: {
    scenario: {
      type: Object,
      required: true,
    },

    levelEntryKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      confirmationDialog: false,
      tensionChartOptions: {
        chart: {
          width: 80,
          height: 20,
          spacing: [5, 0, 5, 0],
          style: {
            margin: '0 auto',
          },
          yAxis: {
            plotLines: [
              {
                value: 0,
                color: 'green',
                dashStyle: 'shortdash',
                width: 2,
              },
            ],
          },
        },
      },
      rules: {
        marginLever: [this.valueRequired, this.valueInRange],
      },
      scenarioToDelete: null,
      isDeleteDialogOpen: false,
      numberFormats,
      displayStoreGroupPenalty,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('scenarioMetadata', ['deleting']),
    ...mapState('gridView', [
      'selectedWeighting',
      'selectedHistoricalPeriod',
      'showRegularImpact',
      'selectedCompetitors',
      'readFromDatabricks',
    ]),
    ...mapState('clientConfig', ['hierarchy']),
    ...mapGetters('context', ['isPricingSpecialist', 'isPricingManager']),
    ...mapGetters('gridView', ['expandedStoreGroupAggregation']),

    storeGroupExpanded() {
      return !!this.expandedStoreGroupAggregation({
        parentId: this.levelEntryKey,
        level: this.hierarchy.pricingGroupLevel,
        scenarioKey: this.scenario.scenarioKey,
      });
    },

    tensionChartProps() {
      return {
        options: this.tensionChartOptions,
        economicReferencePrice: this.selectedPeriodAggregation.economicTension,
        competitorReferencePrice: this.selectedPeriodAggregation.competitorTension,
        architectureReferencePrice: this.selectedPeriodAggregation.architectureTension,
        storeGroupReferencePrice: this.selectedPeriodAggregation.storeGroupTension,
        scenarioPrice: 0,
      };
    },

    validCompetitors() {
      if (this.competitors.length === this.selectedCompetitors.length) return this.competitors;
      // Find missing competitors from selectedCompetitors
      const missingCompetitors = differenceBy(
        this.selectedCompetitors,
        this.competitors,
        'competitorKey'
      );

      return union(this.competitors, missingCompetitors);
    },

    isCandidateScenario() {
      return this.scenario.version === candidate;
    },

    isEditable() {
      return this.isPricingSpecialist || this.isPricingManager;
    },

    canDelete() {
      // TODO: refactor to add and check for scenarioWrite permission instead of role?
      return !this.isCandidateScenario && this.isPricingSpecialist;
    },

    weightingFunctionMap() {
      return {
        [competitorIndexWeightingTypes.sales]: this.sales,
        [competitorIndexWeightingTypes.volume]: this.volume,
        [competitorIndexWeightingTypes.price]: this.price,
      };
    },

    selectedWeightValues() {
      return this.weightingFunctionMap[this.selectedWeighting];
    },

    impacts() {
      const totalImpacts = {
        sales: this.selectedPeriodAggregation.salesImpactMAT / 1000,
        salesInYear: this.selectedPeriodAggregation.totalImpactInYear / 1000,
        cost: this.selectedPeriodAggregation.totalCostImpact / 1000,
        costInYear: this.selectedPeriodAggregation.costImpactInYear / 1000,
      };
      const regularImpacts = {
        sales: this.selectedPeriodAggregation.regularSalesImpact / 1000,
        salesInYear: this.selectedPeriodAggregation.regularImpactInYear / 1000,
        cost: this.selectedPeriodAggregation.regularCostImpact / 1000,
        costInYear: this.selectedPeriodAggregation.regularCostImpactInYear / 1000,
      };
      return this.showRegularImpact ? regularImpacts : totalImpacts;
    },

    sales() {
      if (this.readFromDatabricks) {
        return keyBy(
          this.validCompetitors.map(c => {
            return {
              competitorKey: c.competitorKey,
              competitorLiveDistance: c.competitorLiveDistance,
              competitorProposedDistance: c.competitorProposedDistance,
              competitorDeltaVsPrev: c.competitorDeltaVsPrev,
            };
          }),
          'competitorKey'
        );
      }

      return {
        competitor1LiveDistance: this.selectedPeriodAggregation.competitor1LiveDistance,
        competitor1ProposedDistance: this.selectedPeriodAggregation.competitor1ProposedDistance,
        competitor1DeltaVsPrev: this.selectedPeriodAggregation.competitor1DeltaVsPrev,
        competitor2LiveDistance: this.selectedPeriodAggregation.competitor2LiveDistance,
        competitor2ProposedDistance: this.selectedPeriodAggregation.competitor2ProposedDistance,
        competitor2DeltaVsPrev: this.selectedPeriodAggregation.competitor2DeltaVsPrev,
      };
    },

    price() {
      if (this.readFromDatabricks) {
        return keyBy(
          this.validCompetitors.map(c => {
            return {
              competitorKey: c.competitorKey,
              competitorLiveDistance: c.competitorLiveDistancePrice,
              competitorProposedDistance: c.competitorProposedDistancePrice,
              competitorDeltaVsPrev: c.competitorDeltaVsPrevPrice,
            };
          }),
          'competitorKey'
        );
      }

      return {
        competitor1LiveDistance: this.selectedPeriodAggregation.competitor1LiveDistancePrice,
        competitor1ProposedDistance: this.selectedPeriodAggregation
          .competitor1ProposedDistancePrice,
        competitor1DeltaVsPrev: this.selectedPeriodAggregation.competitor1DeltaVsPrevPrice,
        competitor2LiveDistance: this.selectedPeriodAggregation.competitor2LiveDistancePrice,
        competitor2ProposedDistance: this.selectedPeriodAggregation
          .competitor2ProposedDistancePrice,
        competitor2DeltaVsPrev: this.selectedPeriodAggregation.competitor2DeltaVsPrevPrice,
      };
    },

    volume() {
      if (this.readFromDatabricks) {
        return keyBy(
          this.validCompetitors.map(c => {
            return {
              competitorKey: c.competitorKey,
              competitorLiveDistance: c.competitorLiveDistanceVolume,
              competitorProposedDistance: c.competitorProposedDistanceVolume,
              competitorDeltaVsPrev: c.competitorDeltaVsPrevVolume,
            };
          }),
          'competitorKey'
        );
      }

      return {
        competitor1LiveDistance: this.selectedPeriodAggregation.competitor1LiveDistanceVolume,
        competitor1ProposedDistance: this.selectedPeriodAggregation
          .competitor1ProposedDistanceVolume,
        competitor1DeltaVsPrev: this.selectedPeriodAggregation.competitor1DeltaVsPrevVolume,
        competitor2LiveDistance: this.selectedPeriodAggregation.competitor2LiveDistanceVolume,
        competitor2ProposedDistance: this.selectedPeriodAggregation
          .competitor2ProposedDistanceVolume,
        competitor2DeltaVsPrev: this.selectedPeriodAggregation.competitor2DeltaVsPrevVolume,
      };
    },

    displayBorderUnderName() {
      return !this.isFeatureFlagEnabled(displayStoreGroupPenalty);
    },

    extraDecimalPlacesForMarginDelta() {
      return this.isFeatureFlagEnabled(extraDecimalPlacesForMarginDelta);
    },

    selectedPeriodAggregation() {
      const mapAggregationsBySelectedHistoricalPeriod = mapAggregations(
        this.selectedHistoricalPeriod
      );
      return this.readFromDatabricks
        ? this.scenario
        : mapAggregationsBySelectedHistoricalPeriod(this.scenario);
    },

    competitors() {
      return get(this.selectedPeriodAggregation, 'competitors', []);
    },
  },

  created() {
    this.scenarioDescriptionTruncationLength = 21;
  },

  methods: {
    ...mapActions('gridView', ['handleRecalculate', 'toggleStoreGroupAggregations']),
    ...mapActions('scenarioMetadata', ['updateScenarioMetadata', 'deleteScenario']),

    openModal() {
      this.confirmationDialog = true;
    },

    openDeleteDialog(scenario) {
      this.scenarioToDelete = scenario;
      this.isDeleteDialogOpen = true;
    },

    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.scenarioToDelete = null;
    },

    async runDeleteScenario() {
      this.isDeleteDialogOpen = false;
      await this.deleteScenario(this.scenarioToDelete);
    },

    async changeScenario() {
      await this.$emit('updateDefaultScenario', this.scenario.scenarioKey);
      this.confirmationDialog = false;
    },

    debounceMarginLeverUpdate: debounce(async function(value) {
      // detect if we have an error message
      if (
        typeof this.valueRequired(value) === 'string' ||
        typeof this.valueInRange(value) === 'string'
      )
        return null;

      await this.updateScenarioMetadata({
        id: this.scenario._id,
        updates: { marginLever: this.formatStringToNumber(value) },
      });
      await this.handleRecalculate({
        scenarioKey: this.scenario.scenarioKey,
        workpackageId: this.selectedWorkpackage._id,
      });
    }, clientConfig.inputDebounceValue),

    valueRequired(value) {
      // vuetify component requred a string error to show error state, this text is not used on the frontend
      return !!value || 'Error';
    },

    valueInRange(value) {
      value = this.formatStringToNumber(value);
      // vuetify component requred a string error to show error state, this text is not used on the frontend
      return (value > -100 && value < 100) || 'Error';
    },

    marginLeverChange(newValue) {
      let marginLever = this.formatStringToNumber(newValue);
      if (!marginLever && marginLever !== 0) return;
      marginLever = round(marginLever, 2);
      this.$set(this.scenario, 'marginLever', marginLever);
      this.debounceMarginLeverUpdate(newValue);
    },

    async toggleDisplayStoreGroupAggregations() {
      await this.toggleStoreGroupAggregations({
        parentId: this.levelEntryKey,
        level: this.hierarchy.pricingGroupLevel,
        scenarioKey: this.scenario.scenarioKey,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
$font-size: 1.3rem;

tr:not(.details-row) {
  font-size: $font-size;

  td {
    font-size: $font-size;
    font-weight: 600;
  }
}

tr {
  background-color: white;
  color: $text-color;

  td {
    padding: 0.4rem 0.7rem;
    vertical-align: middle;
    line-height: 1.7rem;
  }

  .alert-cell {
    background-color: $pricing-background !important;
    min-width: $grid-view-alert-column-width !important;
  }

  td.highlight {
    background-color: $grid-view-highlight;
  }
}

tr.selected td {
  background-color: $grid-view-selected-row;
}

.v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.dialog-copy {
  font-size: $font-size;
  line-height: 2rem;
}

.scenario-name {
  font-size: $font-size;
  max-width: 15rem;
}

.margin-lever-container {
  min-width: $grid-view-alert-column-width;
  padding-top: 0.3rem !important; // Vuetify 2.7.2 chaged styles overrides
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

// these classes affect items which are not scoped.
.scenario-selector-row {
  .btn-delete {
    min-width: unset !important;
    padding: unset !important;
    height: 2.5rem !important;
    width: 2.5rem;
  }

  .alert-dialog {
    width: 0;
    height: 0;
  }
  // radio buttons won't have an error message
  .v-messages {
    display: none;
  }

  .v-label {
    font-size: 1.3rem;
  }

  .v-input__slot {
    margin: 0 !important;
  }

  .v-input--radio-group label {
    color: $grid-view-dark-text-color;
  }

  .v-radio--is-disabled label {
    color: $grid-view-dark-text-color;
  }

  .v-radio .v-icon {
    color: $pricing-primary !important;
  }

  .v-radio {
    margin: 0 !important;
  }

  input {
    padding: 0;
    text-align: right;
    font-size: 1.3rem;
  }
  .v-input {
    padding: 0;
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
