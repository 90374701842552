import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      _c("tr", [
        _vm.isUnitLevel
          ? _c("td", { attrs: { colspan: "35" } }, [_c("br"), _c("br")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "tr",
        {
          staticClass: "hierarchy__row hierarchy-level-item__main",
          class: [_vm.hierarchyLevelClass],
        },
        [
          _c(
            "td",
            {
              staticClass:
                "hierarchy-level-item__heading hierarchy-level-item__group-end border-right",
              class: { disabled: _vm.rowDisabled },
            },
            [
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  {
                    staticClass: "clickable my-auto",
                    style: [{ fontSize: _vm.headingSizeInRem + "rem" }],
                    on: { click: _vm.toggleExpansion },
                  },
                  [
                    _vm.isPricingGroupLevel || _vm.isCategoryLevel
                      ? _c("i", {
                          class: [
                            _vm.expanded
                              ? "mdi mdi-chevron-down"
                              : "mdi mdi-chevron-right",
                          ],
                        })
                      : _vm._e(),
                    _vm._v("\n          " + _vm._s(_vm.name) + "\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-end" },
                  [
                    _vm.recalculatingSpecificPgs.length
                      ? _c(
                          VProgressCircular,
                          {
                            attrs: {
                              "custom-title-msg": _vm.$t(
                                "gridView.tooltips.specificPgsRecalculating",
                                {
                                  pgNames: _vm.recalculatingSpecificPgs
                                    .map((pg) => pg.levelEntryDescription)
                                    .join(", "),
                                }
                              ),
                              size: 20,
                              width: 2,
                              color: "primary",
                              indeterminate: "",
                            },
                          },
                          [_vm._v("i")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _vm.isUnitLevel &&
                        _vm.canApproveUnit &&
                        !_vm.unitApproved
                          ? _c(
                              VBtn,
                              {
                                staticClass:
                                  "mr-2 hierarchy-level-item__approve-btn approval-workflow",
                                class: {
                                  "disabled-button":
                                    _vm.isSelectedWorkpackageMaster,
                                },
                                attrs: {
                                  color: "success",
                                  small: "",
                                  depressed: "",
                                  disabled:
                                    _vm.unitApproved ||
                                    _vm.isSelectedWorkpackageMaster,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.approveUnit()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("actions.approve")) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.unitApproved && _vm.isUnitLevel
                          ? _c(
                              "span",
                              {
                                staticClass: "mr-2 approval-text success--text",
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("actions.approved")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("delimiter")(_vm.approvalDate)
                                      )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRootHierarchyLevel
                          ? [_c("recalculate-all-button")]
                          : [
                              _vm.showRecalculateButton
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass:
                                        "mr-2 btn-refresh white--text hierarchy-level-item__recalculate-btn",
                                      attrs: {
                                        color: "primary",
                                        "x-small": "",
                                        depressed: "",
                                        loading: _vm.isLoading,
                                        disabled: _vm.isRecalculateDisabled,
                                      },
                                      on: { click: _vm.triggerRecalculate },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.isRootHierarchyLevel
                                              ? _vm.$t("actions.recalculateAll")
                                              : ""
                                          ) +
                                          "\n                "
                                      ),
                                      _vm._m(0),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                        _vm._v(" "),
                        _vm.isPricingGroupLevel
                          ? _c(
                              "div",
                              {
                                attrs: {
                                  "custom-title-msg": _vm.$t(
                                    "gridView.tooltips.pricingSpecialistRelease"
                                  ),
                                  "custom-title-position": "top-right",
                                },
                              },
                              [
                                _c(VCheckbox, {
                                  staticClass: "ma-0 checkbox",
                                  attrs: {
                                    "input-value": _vm.selectedOrReleased,
                                    readonly:
                                      _vm.pgReleased ||
                                      !_vm.pricingSpecialistAndManagerPricingGroupLevel ||
                                      _vm.isSelectedWorkpackageMaster,
                                    dense: "",
                                    color: "success",
                                    disabled:
                                      _vm.pgReleased ||
                                      !_vm.pricingSpecialistAndManagerPricingGroupLevel ||
                                      _vm.isSelectedWorkpackageMaster,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.togglePricingGroup()
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isPricingGroupLevel
                          ? _c("hierarchy-level-item-approvals", {
                              attrs: { approvals: _vm.approvals },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          VDialog,
                          {
                            attrs: { width: "40rem" },
                            model: {
                              value: _vm.recalculateConfirmationDialog,
                              callback: function ($$v) {
                                _vm.recalculateConfirmationDialog = $$v
                              },
                              expression: "recalculateConfirmationDialog",
                            },
                          },
                          [
                            _c(
                              VCard,
                              [
                                _c(
                                  VCardTitle,
                                  {
                                    staticClass: "headline lighten-2",
                                    attrs: { "primary-title": "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "gridView.recalculate.confirmationDialog.title",
                                            { hierarchy: _vm.name }
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  VCardText,
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "recalculate-dialog pt-5 pb-7",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "gridView.recalculate.confirmationDialog.description",
                                                {
                                                  hierarchy: _vm.get(
                                                    _vm.recalculatingUpperHierarchy,
                                                    "[0].levelEntryDescription",
                                                    ""
                                                  ),
                                                }
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      VCardActions,
                                      [
                                        _c(VSpacer),
                                        _vm._v(" "),
                                        _c(
                                          VBtn,
                                          {
                                            staticClass: "cancel-btn",
                                            attrs: {
                                              outlined: "",
                                              small: "",
                                              text: "",
                                              depressed: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.recalculateConfirmationDialog = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("actions.cancel")
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          VBtn,
                                          {
                                            staticClass: "primary",
                                            attrs: { small: "", depressed: "" },
                                            on: { click: _vm.recalculate },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("actions.submit")
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("aggregations-row", {
            attrs: {
              level: _vm.level,
              "sales-m-a-t": _vm.salesMAT,
              "promo-share": _vm.promoShare,
              "sales-impact-m-a-t": _vm.salesImpactMAT,
              "regular-sales-impact": _vm.regularSalesImpact,
              "total-impact-in-year": _vm.totalImpactInYear,
              "regular-impact-in-year": _vm.regularImpactInYear,
              "total-cost-impact": _vm.totalCostImpact,
              "cost-impact-in-year": _vm.costImpactInYear,
              "regular-cost-impact-in-year": _vm.regularCostImpactInYear,
              "regular-cost-impact": _vm.regularCostImpact,
              "margin-proposed": _vm.marginProposed,
              "margin-delta": _vm.marginDelta,
              "economic-tension": _vm.economicTension,
              "competitor-tension": _vm.competitorTension,
              "architecture-tension": _vm.architectureTension,
              "store-group-tension": _vm.storeGroupTension,
              competitors: _vm.competitors || [],
              "competitor1-live-distance": _vm.competitor1LiveDistance,
              "competitor1-proposed-distance": _vm.competitor1ProposedDistance,
              "competitor1-delta-vs-prev": _vm.competitor1DeltaVsPrev,
              "competitor2-live-distance": _vm.competitor2LiveDistance,
              "competitor2-proposed-distance": _vm.competitor2ProposedDistance,
              "competitor2-delta-vs-prev": _vm.competitor2DeltaVsPrev,
              "competitor1-live-distance-price":
                _vm.competitor1LiveDistancePrice,
              "competitor1-proposed-distance-price":
                _vm.competitor1ProposedDistancePrice,
              "competitor1-delta-vs-prev-price":
                _vm.competitor1DeltaVsPrevPrice,
              "competitor2-live-distance-price":
                _vm.competitor2LiveDistancePrice,
              "competitor2-proposed-distance-price":
                _vm.competitor2ProposedDistancePrice,
              "competitor2-delta-vs-prev-price":
                _vm.competitor2DeltaVsPrevPrice,
              "competitor1-live-distance-volume":
                _vm.competitor1LiveDistanceVolume,
              "competitor1-proposed-distance-volume":
                _vm.competitor1ProposedDistanceVolume,
              "competitor1-delta-vs-prev-volume":
                _vm.competitor1DeltaVsPrevVolume,
              "competitor2-live-distance-volume":
                _vm.competitor2LiveDistanceVolume,
              "competitor2-proposed-distance-volume":
                _vm.competitor2ProposedDistanceVolume,
              "competitor2-delta-vs-prev-volume":
                _vm.competitor2DeltaVsPrevVolume,
              "num-price-changes": _vm.numPriceChanges,
              "competitor-target-distances": _vm.competitorTargetDistances,
            },
            on: {
              updateCompetitorTargetDistances:
                _vm.updateCompetitorTargetDistances,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "feature-toggle",
        { attrs: { toggle: _vm.displayStoreGroupPenalty } },
        [
          _c("store-group-aggregations", {
            key: _vm._id,
            attrs: { "parent-id": _vm._id, level: _vm.level },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCheckboxRow
        ? _c(
            "tr",
            { staticClass: "hierarchy__row checkbox-row" },
            [
              _vm.showExpandChildrenActionRow
                ? _c("hierarchy-level-item-expand-children", {
                    attrs: {
                      expanded: _vm.expanded,
                      level: _vm.level,
                      "level-entry-key": _vm.levelEntryKey,
                      name: _vm.name,
                      "parent-id": _vm.parentId,
                      "workpackage-id": _vm.workpackageId,
                    },
                    on: {
                      categoryReleased: function ($event) {
                        return _vm.toggleCategory()
                      },
                      pricingSpecialistCategoryApproval:
                        _vm.pricingSpecialistCategoryApproval,
                      categoryManagerCategoryApproval:
                        _vm.categoryManagerCategoryApproval,
                      toggle: _vm.toggleExpansion,
                    },
                  })
                : _c("td", { attrs: { colspan: "15" } }),
              _vm._v(" "),
              _c(
                "td",
                {
                  class: {
                    "border-bottom":
                      _vm.areStoreGroupAggregationsVisible &&
                      _vm.showExpandChildrenActionRow,
                  },
                  attrs: { colspan: "2" },
                },
                [
                  _c(
                    "feature-toggle",
                    { attrs: { toggle: _vm.displayStoreGroupPenalty } },
                    [
                      _vm.areStoreGroupAggregationsVisible
                        ? _c(
                            "div",
                            {
                              staticClass: "clickable my-auto",
                              on: {
                                click: _vm.toggleDisplayStoreGroupAggregations,
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.storeGroupExpanded
                                      ? _vm.$t("actions.close")
                                      : _vm.$t("actions.details")
                                  ) +
                                  "\n          "
                              ),
                              _c("i", {
                                class: [
                                  _vm.storeGroupExpanded
                                    ? "mdi mdi-chevron-up"
                                    : "mdi mdi-chevron-down",
                                ],
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { staticClass: "alert-cell" }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { size: "2rem" } }, [_vm._v("mdi-refresh")])
  },
]
render._withStripped = true

export { render, staticRenderFns }