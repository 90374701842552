import priceTypes from '@enums/price-types';

const mixin = {
  methods: {
    getPriceField(priceName) {
      // Based on the selectedPriceType stored in vuex, return the field to be used for prices
      // Only needed by a few components, so not loaded globally
      // priceName: options are `livePrice`, `intentionPrice`, `scenarioPrice`, `competitorPrice`,
      // `scenarioPriceChange`, `scenarioPriceChangeRatio`
      if (this.selectedPriceType === priceTypes.uom) return `${priceName}PerContentUnit`;
      if (this.selectedPriceType === priceTypes.normWeight) return `${priceName}PerNormWeight`;
      return priceName;
    },

    /**
     * when reading documents from workpackage-product collection
     * `livePrice`, `liveCost`, `intentionCost` are returned as an object from BE:
     * livePrice: {price: 1, pricePerContentUnit:1, pricePerNormWeight: 1, ...}
     *
     * intentionPrice is always returned as an object in both workpackage-product and ASR collections
     *
     * for instance:
     * for liveCost field when this.selectedPriceType === priceTypes.uom
     *    getPriceField returns 'liveCostPerContentUnit'
     *    getPriceFieldObjectPath returns 'liveCost.pricePerContentUnit'
     */
    getPriceFieldObjectPath(priceName) {
      return this.getPriceField(`${priceName}.price`);
    },
  },
};

export default mixin;
