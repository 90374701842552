<template>
  <div v-show="hasSelectedWorkpackage" class="input-screen-page-wrapper">
    <v-row no-gutters>
      <v-col>
        <!-- Add/edit attribute metadata -->
        <v-menu v-if="canEditAttributes" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" small depressed v-on="on">
              {{ $t('actions.add') }} <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="openAttributeCreateUpdateDialog(dialogModes.create, attributeScope.global)"
            >
              <v-list-item-title>
                {{ startCase($t('attributes.actions.createGlobalAttribute')) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                openAttributeCreateUpdateDialog(dialogModes.create, attributeScope.pricingGroup)
              "
            >
              <v-list-item-title>
                {{ startCase($t('attributes.actions.createPricingGroupAttribute')) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Upload product hierarchy/attributes -->
        <v-menu v-if="canEditAttributes" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              depressed
              class="pa-0"
              :loading="busyImportingAttributes || busyImportingGroup"
              v-on="on"
            >
              {{ $t('actions.upload') }}
              <v-icon small>$import</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showHierarchyUpload = true">
              <v-list-item-title>
                {{ startCase($t('attributes.uploadHierarchy.uploadButton')) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="showAttributeUpload = true">
              <v-list-item-title>
                {{ startCase($t('attributes.upload.uploadButton')) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="search-field py-0"
          :value="attributeEditorSearch"
          append-icon="search"
          :label="$t('actions.search')"
          single-line
          @input="debounceSearchUpdate"
        />
      </v-col>
    </v-row>

    <attribute-filter-panel
      title-localisation="attributes.filters.filterByAttributes"
      filter-count-localisation="attributes.filters.numApplied"
      enable-hierarchy-filters
      :filter-rules="retailAttributesFilter"
      @attributeFilterChange="setFilterRules"
    />

    <!-- Attribute editor table -->
    <v-data-table
      class="pricing-table elevation-1 attributes-table striped-table"
      :items="items"
      :loading="loadingData"
      :headers="headers"
      :height="dataTableHeight"
      :loading-text="$t('general.loadingMessage')"
      disable-pagination
      disable-sort
      dense
      fixed-header
      hide-default-header
      hide-default-footer
      @mousewheel.native="preventPageNavigationOnHorizontalScroll($event, '.v-data-table__wrapper')"
    >
      <template v-slot:no-data>
        <v-alert v-if="!loadingData" :value="true" color="error" icon="warning" outlined>
          {{ $t('attributes.editor.noAttributesDisplayMessage') }}
        </v-alert>
      </template>

      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th colspan="1" :class="fixedColumnsClass" />
            <!-- FEATURE_FLAG: display a tool store group cell -->
            <th v-if="toolStoreGroupColumn" colspan="1" :class="fixedColumnsClass" />
            <th colspan="1" class="border-right" :class="fixedColumnsClass" />
            <th colspan="3" class="border-right">
              {{ $t('attributes.editor.headers.hierarchy') }}
            </th>
            <th
              v-if="formattedGlobalAttributeHeaders.length > 0"
              :colspan="formattedGlobalAttributeHeaders.length"
            >
              {{ $t('attributes.editor.headers.globalAttributes') }}
            </th>
            <th
              v-if="formattedPricingGroupAttributeHeaders.length > 0"
              :colspan="formattedPricingGroupAttributeHeaders.length"
              class="border-left"
            >
              {{ $t('attributes.editor.headers.pricingGroupAttributes') }}
            </th>
          </tr>
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.id"
              class="sortable"
              :class="[...getHeaderClasses(header, pagination), header.class]"
            >
              <span @click="changeSort(header)">
                <tooltip
                  :disabled="isTooltipDisabled(header.text, truncationLengthMedium)"
                  :value="header.text"
                >
                  {{ header.text | truncate(truncationLengthMedium) }}
                </tooltip>
              </span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="isDynamicAttributeHeader(header.value) && canEditAttributes"
                    class="actions-btn"
                    v-on="on"
                  >
                    more_horiz
                  </v-icon>
                </template>
                <v-list v-if="canEditAttributes">
                  <v-list-item
                    v-if="hasMenuOption(header.value, 'editHierarchyLevel')"
                    @click="openHierarchyUpdateDialog(header)"
                  >
                    <v-list-item-title>
                      {{ $t('attributes.editor.actions.editHierarchyLevel') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasMenuOption(header.value, 'addNewHierarchyLevel')"
                    @click="openHierarchyCreateDialog(header)"
                  >
                    <v-list-item-title>
                      {{ $t('attributes.editor.actions.addNewHierarchyLevel') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="hasMenuOption(header.value, 'deleteHierarchyLevel')"
                    @click="openHierarchyDeleteDialog(header)"
                  >
                    <v-list-item-title>
                      {{ $t('attributes.editor.actions.deleteHierarchyLevel') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasMenuOption(header.value, dialogModes.edit)"
                    @click="openAttributeCreateUpdateDialog(dialogModes.edit, header.scope, header)"
                  >
                    <v-list-item-title>
                      {{ $t('attributes.editor.actions.edit') }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasMenuOption(header.value, dialogModes.delete)"
                    @click="openDeleteDialog(header)"
                  >
                    <v-list-item-title>
                      {{ $t('attributes.editor.actions.delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon size="1.4rem" class="v-data-table-header__icon" @click="changeSort(header)">
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="props">
        <tr :key="props.index">
          <td class="text-xs table-cell" :class="fixedColumnsClass">
            {{ props.item.productKeyDisplay }}
          </td>
          <!-- FEATURE_FLAG: display a tool store group cell -->
          <td v-if="toolStoreGroupColumn" class="text-xs table-cell" :class="fixedColumnsClass">
            <tooltipped-truncated-field
              :text="props.item.toolStoreGroupDescription"
              :truncation-length="truncationLengthMedium"
              class="tooltipped-truncated-field"
            />
          </td>
          <td class="text-xs table-cell border-right" :class="fixedColumnsClass">
            <tooltip
              :value="props.item.productName"
              :disabled="isTooltipDisabled(props.item.productName, truncationLengthLong)"
            >
              {{ props.item.productName | truncate(truncationLengthLong) }}
            </tooltip>
          </td>

          <td class="text-xs table-cell">
            <tooltip
              v-if="!dataReloading"
              :value="getHierarchyName(props.item, categoryLevel)"
              :disabled="
                isTooltipDisabled(
                  getHierarchyName(props.item, categoryLevel),
                  truncationLengthMedium
                )
              "
            >
              {{ getHierarchyName(props.item, categoryLevel) | truncate(truncationLengthMedium) }}
            </tooltip>
          </td>

          <td class="text-xs table-cell">
            <!-- $event passes the string of the chosen pricing group -->
            <!-- The below element should have a permissions check only available to the pricing specialist -->
            <v-select
              v-if="!dataReloading"
              :key="forceRefreshWhenUpdated(props.item, pricingGroupLevel)"
              :items="getSiblingHierarchies(props.item, pricingGroupLevel)"
              item-text="levelEntryDescription"
              :label="getHierarchyName(props.item, pricingGroupLevel)"
              :disabled="!canEditAttributes"
              return-object
              single-line
              dense
              hide-details
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
              @input="updateWorkpackageProductHierarchy(props.item, $event)"
              @focus="$set(props.item, 'selected', true)"
            >
              <template v-slot:label>
                <span class="dropdown-text">
                  {{ getHierarchyName(props.item, pricingGroupLevel) }}
                </span>
              </template>
              <template v-slot:item="props">
                <span class="dropdown-text">{{ props.item.levelEntryDescription }} </span>
              </template>
              <template v-slot:append-item>
                <dropdown-list-item
                  divider
                  :disabled="isHierarchyUnassigned(props.item, pricingGroupLevel)"
                  :title="$t('attributes.actions.unassignPricingGroup')"
                  icon-class="close-circle"
                  @selectOption="
                    updateWorkpackageProductHierarchy(props.item, {
                      levelEntryKey: pricingGroupUndefined,
                      level: pricingGroupLevel,
                    })
                  "
                />
              </template>
              <template v-slot:no-data>
                <span class="dropdown-text no-data-slot d-block pb-2">
                  {{ $t('attributes.editor.noHierarchyInDropdown') }}
                </span>
              </template>
            </v-select>

            <v-select v-else />
          </td>

          <td class="text-xs table-cell border-right">
            <!-- $event passes the string of the chosen architecture group -->
            <v-select
              v-if="!dataReloading"
              :key="forceRefreshWhenUpdated(props.item, architectureGroupLevel)"
              :items="getSiblingHierarchies(props.item, architectureGroupLevel)"
              item-text="levelEntryDescription"
              :label="getHierarchyName(props.item, architectureGroupLevel)"
              :disabled="!canEditArchitectureGroup(props.item)"
              return-object
              single-line
              dense
              hide-details
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
              @input="updateWorkpackageProductHierarchy(props.item, $event)"
              @focus="$set(props.item, 'selected', true)"
            >
              <template v-slot:label>
                <span class="dropdown-text">
                  {{ getHierarchyName(props.item, architectureGroupLevel) }}
                </span>
              </template>
              <template v-slot:item="props">
                <span class="dropdown-text">{{ props.item.levelEntryDescription }}</span>
              </template>
              <template v-slot:append-item>
                <dropdown-list-item
                  divider
                  :disabled="isHierarchyUnassigned(props.item, architectureGroupLevel)"
                  :title="$t('attributes.actions.unassignArchitectureGroup')"
                  icon-class="close-circle"
                  @selectOption="
                    updateWorkpackageProductHierarchy(props.item, {
                      levelEntryKey: architectureGroupUndefined,
                      level: architectureGroupLevel,
                    })
                  "
                />
              </template>
              <template v-slot:no-data>
                <span class="dropdown-text no-data-slot">
                  {{ $t('attributes.editor.noHierarchyInDropdown') }}
                </span>
              </template>
            </v-select>

            <v-select v-else />
          </td>
          <td
            v-for="header in formattedGlobalAttributeHeaders"
            :key="props.item._id + header.id"
            class="text-xs table-cell"
          >
            <!-- TODO: combine the multiple pricing-edit-text-fields  -->
            <template v-if="dateAttribute(header.value)">
              <v-menu
                :disabled="!canEditAttributes"
                :nudge-left="5"
                offset-x
                left
                :top="props.index > pagination.itemsPerPage / 2 - 1"
                min-width="50"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <!-- PRICE-2734 custom field for alt go-live to allow dates to be removed (clearable) -->
                    <v-text-field
                      v-if="
                        toggleLogic.useManualProductGoLiveDate &&
                          productGoLiveDateAttributeKey === header.value
                      "
                      :key="`${props.item._id}-${header.value}`"
                      :disabled="!canEditAttributes"
                      :value="getAttributeValue(props.item, header.value, true)"
                      class="alt-go-live float-left"
                      dense
                      hide-details
                      clearable
                      @click:clear="recordChanges(null, header.value, props.item)"
                    />
                    <pricing-edit-text-field
                      v-else
                      :key="`${props.item._id}-${header.value}`"
                      :disabled="!canEditAttributes"
                      class="float-left"
                      :data-type="getAttributeDataType(header.value)"
                      :value="getAttributeValue(props.item, header.value, true)"
                      :previous-value="getPreviousValue(props.item, header.value, true)"
                      :prevent-formatting="true"
                      :error="updateErrors[props.item.keys[header.value]]"
                      :cell-class="getAttributeEditFieldClass(props.item, header.value)"
                      :tooltip-position="
                        props.index > pagination.itemsPerPage / 2 ? 'top-left' : 'bottom-left'
                      "
                      previous-value-translation-key="attributes.editor"
                    />
                  </div>
                </template>
                <v-date-picker
                  :value="getAttributeValue(props.item, header.value)"
                  :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                  :locale="i18nconfig.fallbackLocale"
                  :min="
                    productGoLiveDateAttributeKey === header.value
                      ? new Date().toISOString().substr(0, 10)
                      : null
                  "
                  @input="recordChanges($event, header.value, props.item)"
                />
              </v-menu>
            </template>
            <template v-else>
              <pricing-edit-text-field
                :key="`${props.item._id}-${header.value}`"
                :disabled="!canEditAttributes"
                class="float-left"
                :data-type="getAttributeDataType(header.value)"
                :number-format="numberFormats.decimal"
                :value="getAttributeValue(props.item, header.value)"
                :previous-value="getPreviousValue(props.item, header.value)"
                :error="updateErrors[props.item.keys[header.value]]"
                :cell-class="getAttributeEditFieldClass(props.item, header.value)"
                :allow-negative="true"
                :tooltip-position="
                  props.index > pagination.itemsPerPage / 2 ? 'top-left' : 'bottom-left'
                "
                previous-value-translation-key="attributes.editor"
                @change="recordChanges($event.target.value, header.value, props.item)"
              />
            </template>
          </td>
          <td
            v-for="(header, index) in formattedPricingGroupAttributeHeaders"
            :key="props.item._id + header.id"
            class="text-xs table-cell"
            :class="index === 0 ? 'border-left' : ''"
          >
            <template v-if="!allowProductAttributeEdit(props.item, header)">
              <span> {{ noValueFound }} </span>
            </template>
            <template v-else-if="dateAttribute(header.value)">
              <v-menu
                :disabled="!canEditAttributes"
                :nudge-left="5"
                offset-x
                left
                :top="props.index > pagination.itemsPerPage / 2 - 1"
                min-width="50"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <pricing-edit-text-field
                      :key="`${props.item._id}-${header.value}`"
                      :disabled="!canEditAttributes"
                      class="float-left"
                      :data-type="getAttributeDataType(header.value)"
                      :value="getAttributeValue(props.item, header.value, true)"
                      :previous-value="getPreviousValue(props.item, header.value, true)"
                      :prevent-formatting="true"
                      :error="updateErrors[props.item.keys[header.value]]"
                      :cell-class="getAttributeEditFieldClass(props.item, header.value)"
                      :tooltip-position="
                        props.index > pagination.itemsPerPage / 2 ? 'top-left' : 'bottom-left'
                      "
                      previous-value-translation-key="attributes.editor"
                    />
                  </div>
                </template>
                <v-date-picker
                  :value="getAttributeValue(props.item, header.value)"
                  :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                  :locale="i18nconfig.fallbackLocale"
                  @input="recordChanges($event, header.value, props.item)"
                />
              </v-menu>
            </template>
            <template v-else>
              <pricing-edit-text-field
                :key="`${props.item._id}-${header.value}`"
                :disabled="!canEditAttributes"
                class="float-left"
                :data-type="getAttributeDataType(header.value)"
                :number-format="numberFormats.decimal"
                :value="getAttributeValue(props.item, header.value)"
                :previous-value="getPreviousValue(props.item, header.value)"
                :error="updateErrors[props.item.keys[header.value]]"
                :cell-class="getAttributeEditFieldClass(props.item, header.value)"
                :allow-negative="true"
                :tooltip-position="
                  props.index > pagination.itemsPerPage / 2 ? 'top-left' : 'bottom-left'
                "
                previous-value-translation-key="attributes.editor"
                @change="recordChanges($event.target.value, header.value, props.item)"
              />
            </template>
          </td>
        </tr>
      </template>

      <template slot="footer">
        <v-flex class="input-screen-sticky-table-footer">
          <v-row justify="end">
            <v-col md="3">
              <v-pagination v-model="pagination.page" :length="pages" depressed @input="loadData" />
            </v-col>
          </v-row>
          <v-row justify="end" class="pr-6 pb-2" align="center">
            <!-- Download product attributes -->
            <v-btn
              color="primary"
              small
              :loading="downloadingItems"
              class="mr-3"
              depressed
              @click="downloadAttributes"
            >
              {{ $t('actions.download') }}
              <v-icon small>$export</v-icon>
            </v-btn>
            <span class="mr-3 btn-divider" />
            <v-btn
              :disabled="!canEditAttributes || !hasUnsavedUpdates || hasInvalidUpdates"
              color="success"
              :loading="showSpinner"
              small
              class="save"
              depressed
              @click="persistUpdates"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-row>
        </v-flex>
      </template>
    </v-data-table>

    <!-- Delete dialog -->
    <alert-dialog
      :is-open="isDeleteDialogOpen"
      :ok-btn-text="$t('actions.delete')"
      :disable-ok-button="
        checkingIfAttributeCanBeDeleted || !attributeCanBeDeleted || deletingAttribute
      "
      @onOk="deleteAttributeHandler(true)"
      @onCancel="deleteAttributeHandler(false)"
    >
      <template v-slot:header>{{ $t('dialogs.deleteDialog.header') }}</template>
      <template v-slot:body>
        <div v-if="!checkingIfAttributeCanBeDeleted && attributeCanBeDeleted">
          {{ $t('dialogs.deleteDialog.body') }}
          <strong>{{ attributeToDelete ? attributeToDelete.text : '' }}</strong>
        </div>
        <div v-else-if="checkingIfAttributeCanBeDeleted && attributeUses.length === 0">
          {{ $t('attributes.editor.deleteMessages.checkingIfTheAttribute') }}
          <strong>{{ attributeToDelete ? attributeToDelete.text : '' }}</strong>
          {{ $t('attributes.editor.deleteMessages.canBeDeleted') }}
          <v-progress-circular indeterminate color="primary" size="20" />
        </div>
        <div v-else>
          <div class="pb-1">
            <strong>{{ attributeToDelete ? attributeToDelete.text : '' }}</strong>
            {{ $t('attributes.editor.deleteMessages.attributeCannotBeDeleted') }}:
          </div>
          <div v-for="(attributeUse, index) in attributeUses" :key="index">
            <div v-if="attributeUse.exists" class="pl-2">
              <v-icon class="pb-1" size="1.7rem" :color="alertsIconColor">warning</v-icon>
              {{
                $t(
                  `attributes.editor.deleteMessages.${attributeUse.translationKey}`,
                  attributeUse.translationParams
                )
              }}
            </div>
          </div>
        </div>
      </template>
    </alert-dialog>

    <add-new-hierarchy-level-dialog
      v-if="!loadingHierarchy && isHierarchyDialogOpen"
      :is-open="isHierarchyDialogOpen"
      :level="level"
      :mode="mode"
      :params="getBaseParams()"
      @closeHierarchyDialog="closeHierarchyDialog"
    />

    <delete-hierarchy-level-dialog
      v-if="!loadingHierarchy && isHierarchyDeleteDialogOpen"
      :is-open="isHierarchyDeleteDialogOpen"
      :level="level"
      @childHierarchyDeleted="closeHierarchyDeleteDialog"
    />

    <attribute-create-update-dialog
      :key="get(attributeToEdit, 'attributeKey', dialogModes.create)"
      :is-open="isAttributeCreateUpdateDialogOpen"
      :scope="attributeCreateUpdateScope"
      :mode="mode"
      :attribute="attributeToEdit"
      :params="getBaseParams()"
    />

    <hierarchy-upload-data
      :show-dialog="showHierarchyUpload"
      :params="getBaseParams()"
      @closeDialog="showHierarchyUpload = false"
    />

    <attribute-upload-data
      :show-dialog="showAttributeUpload"
      :params="getBaseParams()"
      @closeDialog="showAttributeUpload = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  some,
  keys,
  reduce,
  get,
  merge,
  ceil,
  omit,
  map,
  sortBy,
  isNull,
  isNil,
  isNaN,
  find,
  startCase,
  size,
  filter,
  intersection,
  includes,
  uniq,
  debounce,
  has,
  forEach,
  every,
  isEmpty,
} from 'lodash';
// TODO: add webpack alias for webtool root to avoid nasty paths
import {
  categoryLevel,
  pricingGroupLevel,
  architectureGroupLevel,
  pricingGroupUndefined,
  architectureGroupUndefined,
} from '@enums/hierarchy';
import sortDirectionEnums from '@enums/sort-direction';
import dialogModes from '@enums/dialog-modes';
import { useZones } from '@enums/feature-flags';
import attributeScope from '@enums/attribute-scope';
import isTooltipDisabled from '../../../utils/tooltip-util';
import DataTypes from '@enums/data-types';
import numberFormats from '@enums/number-formats';
import { inputTableCssClasses } from '@enums/tables';
import colours from '../../../ow-colors';
import inputUpdateKey from '../../../mixins/inputUpdateKeys';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tablesMixin from '../../../mixins/tables';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import { preventPageNavigationOnHorizontalScroll } from '../../../utils/data-table-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [inputUpdateKey, featureFlagsMixin, tablesMixin, inputScreensTableMixin],
  data() {
    return {
      categoryLevel,
      pricingGroupLevel,
      architectureGroupLevel,
      pricingGroupUndefined,
      architectureGroupUndefined,
      useZones,
      pagination: {
        // used by v-data-table for sorting and pagination
        descending: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: 'productKeyDisplay',
        sortDataType: DataTypes.str,
      },
      updates: {}, // key of the form 'productId::attributeKey'
      updateErrors: {}, // key of the form 'productId::attributeKey'
      updatedRowsDetailsMap: {},
      numberFormats,
      isDeleteDialogOpen: false,
      attributeToDelete: null,
      checkingIfAttributeCanBeDeleted: false,
      attributeUses: [],
      alertsIconColor: colours.alertsIconColor,
      isHierarchyDialogOpen: false,
      isHierarchyDeleteDialogOpen: false,
      isAttributeCreateUpdateDialogOpen: false,
      level: null,
      mode: dialogModes.create,
      attributeToEdit: null,
      deletingAttribute: false,
      truncationLengthLong: 53,
      truncationLengthMedium: 28,
      workProductHierarchyUpdates: {},
      dataReloading: true,
      productGoLiveDateAttributeKey: null,
      attributeMenuOptions: {
        [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: [
          'editHierarchyLevel',
          'addNewHierarchyLevel',
          'deleteHierarchyLevel',
        ],
        [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: [
          'editHierarchyLevel',
          'addNewHierarchyLevel',
          'deleteHierarchyLevel',
        ],
        default: [dialogModes.edit, dialogModes.delete],
      },
      dialogModes,
      attributeScope,
      startCase,
      get,
      attributeCreateUpdateScope: attributeScope.global, // default value
      noValueFound: '-',
      attributeEditorSearch: '',
      showSpinner: false,
      showHierarchyUpload: false,
      showAttributeUpload: false,
    };
  },

  events: {
    onSuccessfulAttributeUpdate() {
      this.clearUpdates();
    },

    onSaveNewAttribute() {
      this.closeAttributeCreateUpdateDialog();
    },

    onCancelAddAttribute() {
      this.closeAttributeCreateUpdateDialog();
    },

    onSaveUpdatedAttribute() {
      this.closeAttributeCreateUpdateDialog();
    },
  },

  computed: {
    ...mapState('attributes', [
      'attributes',
      'attributeMetadata',
      'loading',
      'count',
      'downloadingItems',
      'attributeFetchParams',
      'attributesFilterRules',
      'busyImportingAttributes',
    ]),
    ...mapState('hierarchy', {
      loadingHierarchy: 'loading',
      busyImportingGroup: 'busyImportingGroup',
    }),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('filters', ['inputScreensFetchParams', 'retailAttributesFilter']),

    ...mapGetters('hierarchy', [
      'hierarchyIdNameMap',
      'getHierarchy',
      'getHierarchies',
      'getHierarchiesByParentId',
      'getHierarchyParentId',
    ]),
    ...mapGetters('workpackages', ['hasSelectedWorkpackage']),
    ...mapGetters('attributes', ['globalAttributes', 'pricingGroupAttributes']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapGetters('filters', ['getSelectedFilter']),
    ...mapState('clientConfig', [
      'i18nconfig',
      'exportConfigs',
      'dateFormats',
      'toggleLogic',
      'tooldata',
    ]),

    toolStoreGroupColumn() {
      return this.isFeatureFlagEnabled(useZones)
        ? {
            ...this.toolStoreGroupColumnDefaultProperties,
            text: this.$t('pricing.toolStoreGroup'),
            class: `m-width ${inputTableCssClasses.threeFixedColumns}`,
          }
        : null;
    },

    fixedColumnsClass() {
      return this.toolStoreGroupColumn
        ? inputTableCssClasses.threeFixedColumns
        : inputTableCssClasses.twoFixedColumns;
    },

    exportTranslationMap() {
      const toolStoreGroupTranslation = this.getColumnExportTranslation(this.toolStoreGroupColumn);
      return {
        productKeyDisplay: this.$t('pricing.productKey'),
        ...toolStoreGroupTranslation,
        productName: this.$t('pricing.productDescription'),
        category: this.$t('pricing.category'),
        pricingGroup: this.$t('pricing.pricingGroup'),
        architectureGroup: this.$t('pricing.architectureGroup'),
      };
    },

    staticHeaders() {
      const toolStoreGroupHeaders = this.toolStoreGroupColumn
        ? [this.toolStoreGroupColumn.value]
        : [];
      return [
        'productKeyDisplay',
        'productName',
        `hierarchy.${categoryLevel}.levelEntryDescription`,
        ...toolStoreGroupHeaders,
      ];
    },

    agFromExpandedProductView() {
      return this.getSelectedFilter('newTabArchitectureGroupAttributeFilter');
    },

    canEditAttributes() {
      return this.isPricingSpecialist;
    },

    loadingData() {
      // this.loading = loading product-attributes
      return this.loading || this.loadingHierarchy;
    },

    pages() {
      return ceil(this.count / get(this.pagination, 'itemsPerPage'));
    },

    hasUnsavedUpdates() {
      return keys(this.updates).length > 0 || keys(this.workProductHierarchyUpdates).length > 0;
    },

    hasInvalidUpdates() {
      // PG and AG groups must both be unassigned or assigned a valid value
      const hasInvalidHierarchyUpdates = some(this.workProductHierarchyUpdatesMap, product => {
        return (
          (product[pricingGroupLevel] !== pricingGroupUndefined &&
            product[architectureGroupLevel] === architectureGroupUndefined) ||
          (product[pricingGroupLevel] === pricingGroupUndefined &&
            product[architectureGroupLevel] !== architectureGroupUndefined)
        );
      });
      return hasInvalidHierarchyUpdates || some(this.updateErrors);
    },

    headers() {
      // If updating a header be sure to check other places in the code where this may
      // cause issues like attributeMenuOptions or staticHeaders
      const tsgHeader = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];

      return [
        {
          text: this.$t('pricing.productKey'),
          align: 'start',
          value: 'productKeyDisplay',
          class: `s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeader,
        {
          text: this.$t('pricing.productDescription'),
          align: 'start',
          value: 'productName',
          class: `l-width border-right ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        {
          text: this.$t('pricing.category'),
          align: 'start',
          value: `hierarchy.${categoryLevel}.levelEntryDescription`,
          class: 'm-width',
          dataType: DataTypes.str,
        },
        {
          text: this.$t('pricing.pricingGroup'),
          align: 'start',
          value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
          hierarchyLevel: pricingGroupLevel,
          class: 'm-width',
          dataType: DataTypes.str,
        },
        {
          text: this.$t('pricing.architectureGroup'),
          align: 'start',
          value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
          hierarchyLevel: architectureGroupLevel,
          class: 'border-right m-width',
          dataType: DataTypes.str,
        },
        ...this.formattedGlobalAttributeHeaders,
        ...this.formattedPricingGroupAttributeHeaders,
      ];
    },

    formattedGlobalAttributeHeaders() {
      return map(
        filter(
          this.globalAttributes,
          attr => attr.attributeKey !== this.toolStoreGroupGlobalAttributeKey
        ),
        header => ({
          text: header.displayDescription,
          align: 'start',
          value: header.attributeKey,
          isAttribute: true,
          id: header._id,
          source: header.sourceFieldDescription,
          width: '200',
          scope: header.scope,
          ...this.getHeaderFormatter(header),
        })
      );
    },

    selectedPricingGroupsForPgAttributes() {
      const selectedPricingGroups = get(
        find(this.retailAttributesFilter, { attributeKey: pricingGroupLevel }),
        'attributeValue',
        []
      );
      const selectedArchitectureGroups = get(
        find(this.retailAttributesFilter, { attributeKey: architectureGroupLevel }),
        'attributeValue'
      );

      const architectureGroupParents = map(selectedArchitectureGroups, ag =>
        this.getHierarchyParentId(ag)
      );

      return uniq([...selectedPricingGroups, ...architectureGroupParents]);
    },

    availablePricingGroupAttributes() {
      return filter(this.pricingGroupAttributes, pgAttribute =>
        size(intersection(this.selectedPricingGroupsForPgAttributes, pgAttribute.pricingGroups))
      );
    },

    formattedPricingGroupAttributeHeaders() {
      return map(this.availablePricingGroupAttributes, (header, index) => ({
        text: header.displayDescription,
        align: 'start',
        value: header.attributeKey,
        isAttribute: true,
        id: header._id,
        source: header.sourceFieldDescription,
        width: '200',
        scope: header.scope,
        class: index === 0 ? 'border-left' : '',
        pricingGroups: header.pricingGroups,
        ...this.getHeaderFormatter(header),
      }));
    },

    items() {
      // we can't stop vuetify from sorting the items in memory so we temporarily clear the items
      // whilst loading so that it doesn't look they get sorted twice
      if (this.loadingData) return [];
      return this.attributes.map(attribute => {
        attribute.keys = {};
        this.formattedGlobalAttributeHeaders.forEach(attribHeader => {
          attribute.keys[attribHeader.value] = this.getInputUpdateKey(
            attribute._id,
            attribHeader.value,
            attribute.hierarchy[architectureGroupLevel].id
          );
        });
        this.formattedPricingGroupAttributeHeaders.forEach(attribHeader => {
          attribute.keys[attribHeader.value] = this.getInputUpdateKey(
            attribute._id,
            attribHeader.value,
            attribute.hierarchy[architectureGroupLevel].id
          );
        });
        return attribute;
      });
    },

    productKeyHierarchyIdMap() {
      return map(this.attributes, ({ productKeyDisplay, hierarchy }) => ({
        // TODO: (not critical) remove this mapping, use attribute hierarchy directly
        [productKeyDisplay]: {
          [categoryLevel]: hierarchy[categoryLevel].id,
          [pricingGroupLevel]: hierarchy[pricingGroupLevel].id,
          [architectureGroupLevel]: hierarchy[architectureGroupLevel].id,
        },
      })).reduce(
        (combinedPairs, keyPricingGroupPair) => ({ ...combinedPairs, ...keyPricingGroupPair }),
        {}
      );
    },

    workProductHierarchyUpdatesMap() {
      return reduce(
        this.workProductHierarchyUpdates,
        (accumulator, hierarchyLevelEntryId, compoundKey) => {
          const [productKeyDisplay, hierarchyLevel] = this.parseInputUpdateKey(compoundKey);
          return merge(accumulator, {
            [productKeyDisplay]: { [hierarchyLevel]: hierarchyLevelEntryId },
          });
        },
        {}
      );
    },

    attributeCanBeDeleted() {
      return every(this.attributeUses, res => res.exists === false);
    },
  },

  async created() {
    if (this.hasSelectedWorkpackage) {
      // When user opens the attribute-editor make sure attribute metadata is reloaded
      this.fetchAttributeMetadata();
      // if the page is navigated from Expanded Product View,
      // loadData() will be triggered via setFilterRules() emitted by attribute-filter-panel component
      if (!this.agFromExpandedProductView) {
        const fromPricingView = this.$route.meta.previousRoute.includes('/pricing/');
        if (isEmpty(this.attributes) || fromPricingView) await this.loadData();
        else {
          this.dataReloading = false;
          this.pagination = this.inputScreensFetchParams;
          this.pagination.itemsPerPage = 25;
          this.pagination.page = this.getDefaultSelectedPage(this.pagination, this.pages);
          this.attributeEditorSearch = this.pagination.search;
          if (has(this.inputScreensFetchParams, 'pick')) {
            this.loadData();
          }
        }
      }
      const productGoLiveDateAttribute = this.attributeMetadata.find(
        v => v.attributeDescription === get(this.tooldata, 'productGoLiveDateAttributeName')
      );
      this.productGoLiveDateAttributeKey = get(productGoLiveDateAttribute, 'attributeKey');
      await this.fetchHierarchy();
    }
  },

  methods: {
    ...mapActions('attributes', [
      'fetchAttributesAggregated',
      'fetchAttributeMetadata',
      'updateAttributes',
      'downloadItems',
      'deleteAttributeMetadata',
      'checkForAttributeUses',
      'doesProductExistWithUnassignedPGAttribute',
    ]),
    ...mapActions('hierarchy', ['fetchHierarchy', 'doesProductExistWithIncompleteHierarchy']),
    ...mapActions('workpackageProducts', ['updateHierarchy']),
    ...mapActions('architectureDrivers', [
      'updateArchitectureDriversFromAttributeEditorChanges',
      'updateArchitectureDriversWithHierarchyChanges',
    ]),
    ...mapActions('filters', ['setSelectedFilter']),

    allowProductAttributeEdit(product, attribute) {
      if (size(get(attribute, 'pricingGroups'))) {
        return includes(attribute.pricingGroups, product.hierarchy[pricingGroupLevel].id);
      }

      // if pricingGroups is empty, return true as its a global attribute
      return true;
    },

    isTooltipDisabled,
    preventPageNavigationOnHorizontalScroll,

    getFormattedDate(date) {
      return date ? this.formatLocalisedDate(date) : '';
    },

    getAttributeValue(product, attributeKey, dateFormat = false) {
      const value = !isNil(this.updates[product.keys[attributeKey]])
        ? this.updates[product.keys[attributeKey]]
        : get(find(product.attributes, { attributeKey }), 'attributeValue');
      if (dateFormat) return this.getFormattedDate(value);
      return value;
    },

    getPreviousValue(product, attributeKey, dateFormat = false) {
      const value = get(find(product.attributes, { attributeKey }), 'attributeValue');
      if (dateFormat) return this.getFormattedDate(value);
      return value;
    },

    getAttributeEditFieldClass(product, attributeKey) {
      // invalid value entered
      if (this.updateErrors[product.keys[attributeKey]]) {
        return 'attribute-cell-error';
      }
      // value edited and valid (now shown if the updated value is the same as the original)
      if (
        !isNil(this.updates[product.keys[attributeKey]]) &&
        this.updates[product.keys[attributeKey]] !==
          get(find(product.attributes, { attributeKey }), 'attributeValue')
      ) {
        return 'attribute-cell-updated';
      }

      return '';
    },

    getAttributeDataType(attributeKey) {
      return get(find(this.attributeMetadata, { attributeKey }), 'dataType', DataTypes.str);
    },

    dateAttribute(attributeKey) {
      return this.getAttributeDataType(attributeKey) === DataTypes.date;
    },

    downloadAttributes() {
      const dateAttributes = filter(
        this.attributeMetadata,
        attr => attr.dataType === DataTypes.date
      );
      this.downloadItems({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        dateAttributes,
        columnFormatters: this.getColumnFormatters(this.headers),
      });
    },

    getHeaderFormatter(header) {
      const headerFormatter = {};
      if (header.dataType === DataTypes.date) {
        headerFormatter.formatter = {
          type: header.dataType,
          format: this.dateFormats.clientDateFormatForMoment,
        };
      }
      return headerFormatter;
    },

    hasMenuOption(attributeName, option) {
      // explicitly stop product go live date from being edited / deleted when loaded as manual attribute in tooldata
      if (
        this.toggleLogic.useManualProductGoLiveDate &&
        [
          get(this.tooldata, 'productGoLiveDateAttributeName'),
          this.productGoLiveDateAttributeKey,
        ].includes(attributeName)
      )
        return false;
      return (
        this.attributeMenuOptions[attributeName] || this.attributeMenuOptions.default
      ).includes(option);
    },

    canEditArchitectureGroup({ productKeyDisplay, hierarchy }) {
      // Disable editing of AG if PG value is unassigned
      return (
        this.canEditAttributes &&
        !this.isHierarchyUnassigned({ productKeyDisplay, hierarchy }, pricingGroupLevel)
      );
    },

    isHierarchyUnassigned({ productKeyDisplay, hierarchy }, level) {
      const hierarchyValue = get(
        this.workProductHierarchyUpdatesMap,
        [productKeyDisplay, level],
        hierarchy[level].id
      );
      return includes([pricingGroupUndefined, architectureGroupUndefined, null], hierarchyValue);
    },

    getSiblingHierarchies({ productKeyDisplay, selected }, level) {
      // Returns all available pricing groups or architecture groups
      // If a PG update has been selected, returns the AG for that new PG as that is now the valid scope.
      if (this.dataReloading || this.loadingHierarchy || !selected) return;
      const existingUpdate = this.getQueuedUpdate(productKeyDisplay, pricingGroupLevel);

      // If there's a PG update queued, show the AG children for that new PG
      if (existingUpdate && level === architectureGroupLevel) {
        return this.getHierarchiesByParentId[existingUpdate];
      }

      // if the parentId isn't defined, there isn't an existing PG/ AG hierarchy to the wpp
      // this happens at initial data load
      // in this case, return nothing. otherwise, return everything.
      if (!get(this.productKeyHierarchyIdMap, productKeyDisplay)) return [];
      const parentId = this.productKeyHierarchyIdMap[productKeyDisplay][level - 1];
      return isNull(parentId)
        ? []
        : sortBy(this.getHierarchiesByParentId[parentId], 'levelEntryDescription');
    },

    updateWorkpackageProductHierarchy({ productKeyDisplay }, { levelEntryKey, level }) {
      const updateKey = this.getInputUpdateKey(productKeyDisplay, level); // always used, can be PG or AG
      const childUpdateKey = this.getInputUpdateKey(productKeyDisplay, architectureGroupLevel); // only used if PG is updated

      // if we get the original value, we don't add an update and clear any existing updates
      const isOriginalValue =
        levelEntryKey === this.productKeyHierarchyIdMap[productKeyDisplay][level];

      // we always update the selected hierarchy
      let updates = [
        {
          [updateKey]: levelEntryKey,
        },
      ];

      // If updating PG, we must update the AG as they're scoped to a given PG
      if (level === pricingGroupLevel) {
        // Add the update for the AG group
        // If the PG was unassigned, unassign the AG
        updates = [
          ...updates,
          {
            [childUpdateKey]: architectureGroupUndefined,
          },
        ];
      }

      // If selecting or returning to the original value, clear existing updates for that product
      // Otherwise, add all necessary updates
      this.workProductHierarchyUpdates = isOriginalValue
        ? omit(this.workProductHierarchyUpdates, [updateKey, childUpdateKey])
        : merge({}, this.workProductHierarchyUpdates, ...updates);
    },

    getQueuedUpdate(productKeyDisplay, level) {
      return this.workProductHierarchyUpdates[this.getInputUpdateKey(productKeyDisplay, level)];
    },

    forceRefreshWhenUpdated(item, level) {
      // This is a filthy hacky function which forces the v-select component for AGs and PGs to refresh
      // It does this whenever an update is queued or removed for that product
      // Otherwise, vuetify caches the result and we get inconsistency between the update and the displayed text
      return this.getInputUpdateKey(item.productKeyDisplay, this.getHierarchyName(item, level));
    },

    validateEntry(value, attributeKey, product) {
      if (value === null) return true;
      const formattedNumber = this.formatStringToNumber(value);
      const dataType = this.getAttributeDataType(attributeKey);
      const valid =
        dataType === DataTypes.number && value
          ? formattedNumber === 0 || formattedNumber // 0 would be invalid without explicit check
          : true;
      if (valid) {
        // Remove this value from updateErrors, so the page can be saved
        this.$delete(this.updateErrors, product.keys[attributeKey]);
      } else {
        // Add this value to updateErrors
        this.$set(
          this.updateErrors,
          product.keys[attributeKey],
          this.$t('validationErrors.incorrectDataType', {
            dataType,
          })
        );
      }
      return !!valid;
    },

    recordChanges(rawValue, attributeKey, product) {
      this.validateEntry(rawValue, attributeKey, product);
      const formattedValue =
        this.getAttributeDataType(attributeKey) === DataTypes.number && rawValue
          ? this.formatValue(rawValue)
          : rawValue;
      this.saveUpdates(product, product.keys[attributeKey], formattedValue);
    },

    formatValue(rawValue) {
      const numericValue = this.formatStringToNumber(rawValue);
      return isNaN(numericValue) ? rawValue : numericValue;
    },

    formatUpdatesForSave() {
      return reduce(
        this.updates,
        (updatePayload, newValue, updateKey) => {
          const [productId, attributeKey] = this.parseInputUpdateKey(updateKey);
          const updatedRow = this.updatedRowsDetailsMap[productId];
          if (!updatedRow) return updatePayload;
          const { productKeyDisplay, toolStoreGroupKey } = updatedRow;
          return merge(updatePayload, {
            [productKeyDisplay]: {
              // '' means all tool store groups
              [toolStoreGroupKey || '']: {
                [`attributes.${attributeKey}`]: newValue === '' ? null : newValue,
              },
            },
          });
        },
        {}
      );
    },

    formatUpdatesForArchitectureDrivers() {
      const updates = {};
      let attributeKey;
      let productKeyDisplay;
      let productId;
      let architectureGroupId;
      let newKey;
      const hierarchyUpdates = this.formatWorkpackageProductHierarchiesForSave();

      forEach(Object.keys(this.updates), updateKey => {
        [productId, attributeKey, architectureGroupId] = this.parseInputUpdateKey(updateKey);
        // Check if a product has an attribute updated and it's hierarchy. Make sure the update is associated with the correct hierarchy.
        const updatedRow = this.updatedRowsDetailsMap[productId];
        if (!updatedRow) return;
        productKeyDisplay = updatedRow.productKeyDisplay;
        const hierarchyToUpdate = hierarchyUpdates.find(
          hierarchyUpdate => hierarchyUpdate.productKeyDisplay === productKeyDisplay
        );

        newKey = this.getInputUpdateKey(
          attributeKey,
          hierarchyToUpdate ? hierarchyToUpdate.architectureGroupId : architectureGroupId
        );

        const update = this.updates[updateKey];

        if (!has(updates, newKey)) {
          updates[newKey] = [update];
          return;
        }

        updates[newKey].push(update);
      });

      return updates;
    },

    formatWorkpackageProductHierarchiesForSave() {
      return map(this.workProductHierarchyUpdatesMap, (update, productKeyDisplay) => {
        const architectureGroupId = update[architectureGroupLevel];
        const architectureGroupDescription = get(
          this.hierarchyIdNameMap,
          architectureGroupId,
          null
        );

        const updateSpec = {
          productKeyDisplay,
          architectureGroupId:
            architectureGroupId !== architectureGroupUndefined ? architectureGroupId : null,
          architectureGroupDescription,
        };

        // PG value could be null so must check specifically for the PG key in the update
        if (has(update, pricingGroupLevel)) {
          const pricingGroupId = update[pricingGroupLevel];
          const pricingGroupDescription = get(this.hierarchyIdNameMap, pricingGroupId, null);
          merge(updateSpec, {
            pricingGroupId: pricingGroupId !== pricingGroupUndefined ? pricingGroupId : null,
            pricingGroupDescription,
          });
        }
        return updateSpec;
      });
    },

    formatUpdatesForArchitectureDriversFromHierarchyChanges() {
      const updates = this.formatWorkpackageProductHierarchiesForSave();
      const formattedUpdates = updates.map(update => {
        return {
          ...update,
          previousArchitectureGroupId: this.productKeyHierarchyIdMap[update.productKeyDisplay][
            architectureGroupLevel
          ],
        };
      });
      return formattedUpdates;
    },

    async persistUpdates() {
      this.showSpinner = true;
      const params = {
        ...this.getBaseParams(),
        where: this.retailAttributesFilter,
      };
      await Promise.all([
        this.updateAttributes({
          updates: this.formatUpdatesForSave(),
          params,
        }),
        this.updateHierarchy({
          updates: this.formatWorkpackageProductHierarchiesForSave(),
        }),
      ]);
      const architectureDriverHierarchyUpdates = this.formatUpdatesForArchitectureDriversFromHierarchyChanges();
      await this.updateArchitectureDriversWithHierarchyChanges({
        updates: architectureDriverHierarchyUpdates,
      });
      const updates = this.formatUpdatesForArchitectureDrivers();
      this.updateArchitectureDriversFromAttributeEditorChanges(updates);
      this.showSpinner = false;

      const promises = [this.doesProductExistWithIncompleteHierarchy()];
      if (this.toggleLogic.runProductExistWithUnassignedPGAttribute) {
        promises.push(this.doesProductExistWithUnassignedPGAttribute());
      }
      Promise.all(promises);

      this.loadData();
      this.workProductHierarchyUpdates = {};
      this.clearUpdates();
    },

    async loadData() {
      this.dataReloading = true;
      await this.loadAttributes();
      this.dataReloading = false;
    },

    setFilterRules(filterRules) {
      // Reset the pagination as there might be no results on page X
      this.pagination.page = 1;

      this.setSelectedFilter({ filterName: 'retailAttributesFilter', filterValue: filterRules });
      this.loadData();
    },

    getHierarchyName({ productKeyDisplay, hierarchy }, level) {
      // Check if updates exist and show the label of that instead of the original value
      const idOfQueuedUpdate = this.getQueuedUpdate(productKeyDisplay, level);
      // We return the name of the queued ID for updating if it exists
      // If there is no mapping, it returns the value which is an error like 'AG_UNDEFINED'
      if (idOfQueuedUpdate) return this.hierarchyIdNameMap[idOfQueuedUpdate] || idOfQueuedUpdate;
      return hierarchy[level].levelEntryDescription;
    },

    getBaseParams() {
      let params = {
        limit: this.pagination.itemsPerPage,
        offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
        where: this.retailAttributesFilter,
        search: this.attributeEditorSearch,
      };

      if (this.pagination.sortBy) {
        params = {
          ...params,
          sortBy: this.pagination.sortBy,
          sortDirection: this.pagination.descending
            ? sortDirectionEnums.descending
            : sortDirectionEnums.ascending,
          sortDataType: this.pagination.sortDataType,
        };
      }

      return params;
    },

    loadAttributes() {
      const params = this.getBaseParams();
      return this.fetchAttributesAggregated({ params });
    },

    debounceSearchUpdate: debounce(function(value) {
      this.attributeEditorSearch = value;
      this.pagination.page = 1;
      this.loadAttributes();
    }, clientConfig.inputDebounceValue),

    async changeSort(column) {
      const columnValue = this.getColumnValue(column);
      if (!columnValue) return;
      if (this.pagination.sortBy === columnValue) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = columnValue;
        this.pagination.descending = false;
        this.pagination.sortDataType = column.dataType || this.getAttributeDataType(columnValue);
      }
      const params = this.getBaseParams();
      return this.fetchAttributesAggregated({ params, sortingOnly: true });
    },

    isDynamicAttributeHeader(attributeKey) {
      return !this.staticHeaders.includes(attributeKey);
    },

    async openDeleteDialog(attribute) {
      this.checkingIfAttributeCanBeDeleted = true;
      this.attributeToDelete = attribute;
      this.isDeleteDialogOpen = true;
      this.attributeUses = await this.checkForAttributeUses(attribute);
      this.checkingIfAttributeCanBeDeleted = false;
    },

    async deleteAttributeHandler(shouldDelete = false) {
      this.deletingAttribute = true;
      if (shouldDelete) {
        await this.deleteAttributeMetadata({
          attributeKey: this.attributeToDelete.value,
          params: this.getBaseParams(),
        });
        if (this.toggleLogic.runProductExistWithUnassignedPGAttribute) {
          this.doesProductExistWithUnassignedPGAttribute();
        }
      }
      this.deletingAttribute = false;
      this.attributeToDelete = null;
      this.isDeleteDialogOpen = false;
      this.attributeUses = [];
    },

    openAttributeCreateUpdateDialog(mode, scope, attribute = null) {
      this.mode = mode;
      this.attributeCreateUpdateScope = scope;
      if (attribute)
        this.attributeToEdit = find(this.attributeMetadata, { attributeKey: attribute.value });

      this.isAttributeCreateUpdateDialogOpen = true;
    },

    closeAttributeCreateUpdateDialog() {
      this.attributeToEdit = null;
      this.isAttributeCreateUpdateDialogOpen = false;
    },

    openHierarchyCreateDialog(header) {
      this.mode = dialogModes.create;
      this.level = header.hierarchyLevel;
      this.isHierarchyDialogOpen = true;
    },

    openHierarchyUpdateDialog(header) {
      this.mode = dialogModes.edit;
      this.level = header.hierarchyLevel;
      this.isHierarchyDialogOpen = true;
    },

    openHierarchyDeleteDialog(header) {
      this.level = header.hierarchyLevel;
      this.isHierarchyDeleteDialogOpen = true;
    },

    closeHierarchyDeleteDialog() {
      this.isHierarchyDeleteDialogOpen = false;
    },

    closeHierarchyDialog() {
      this.isHierarchyDialogOpen = false;
    },

    clearUpdates() {
      this.updates = {};
      this.updatedRowsDetailsMap = {};
    },

    saveUpdates(product, uniqueKey, update) {
      this.$set(this.updates, uniqueKey, update);
      this.markRowAsUpdated(product);
    },

    markRowAsUpdated(row) {
      if (this.updatedRowsDetailsMap[row._id]) {
        return;
      }

      this.updatedRowsDetailsMap[row._id] = {
        productKeyDisplay: row.productKeyDisplay,
        toolStoreGroupKey: row.toolStoreGroupKey,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables';
.v-data-table--fixed-header {
  thead tr {
    &:nth-child(1) th {
      box-shadow: none;
      font-weight: 600;
      height: 2rem;
    }
    &:nth-child(2) th {
      top: 2rem;
      height: 2rem;
    }
  }
}

.main-header th {
  position: relative;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.theme--light.v-data-table .v-data-table-header th {
  color: rgba(0, 0, 0, 0.38);
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.alt-go-live {
  margin: 0;
  padding: 0;
  // match pricing-edit-text-field
  &::v-deep input {
    min-width: 70px;
    padding: 0;
  }
}

.date-attribute-cell {
  position: relative;
}
</style>
